import { z } from "zod";

export const SmaCrossFormSchema = z.object({
	symbol: z.string().min(1, "required"),
	sma1: z.number({ invalid_type_error: "sma1 is required" }),
	sma2: z.number({ invalid_type_error: "sma2 is required" }),
	sma1_type: z.string(),
	sma2_type: z.string(),
	start_offset: z
		.string()
		.min(1, "required")
		.regex(/^-?[0-9]\d*(\d+)?$/, "Only numbers are allowed")
		.transform((val) => parseInt(val)),
	end_offset: z
		.string()
		.min(1, "required")
		.regex(/^[0-9]\d*(\d+)?$/, "Only Positive numbers are allowed")
		.transform((val) => parseInt(val)),
	buy_type: z.string(),
	sell_type: z.string(),
	start_year: z.number({ invalid_type_error: "Start Year is required" }),
});

export type TSmaCrossForm = z.infer<typeof SmaCrossFormSchema>;
