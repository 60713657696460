import { useCallback, useMemo, useState } from "react";
import {
	Autocomplete,
	Button,
	Grid,
	Icon,
	IconButton,
	TextField,
	Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { isNil, uniqueId } from "lodash";
import dayjs, { Dayjs } from "dayjs";
import { toast } from "react-toastify";
import {
	getFilterData,
	listUserFilterRecords,
	saveFilterRecord,
} from "../../utils/axios/filter";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Filter } from "../../schemas/TakeProfitTargetUs";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const FilterPage = () => {
	const [startDate, setStartDate] = useState<Dayjs | null>(null);
	const [endDate, setEndDate] = useState<Dayjs | null>(null);
	const [cost, setCost] = useState(100);
	const [submit, setSubmit] = useState(false);
	const [symbols, setSymbols] = useState("");
	const [listNumber, setListNumber] = useState("1");
	const [commission, setCommission] = useState(0.001);

	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const { data, isLoading, isFetching, refetch } = useQuery({
		queryKey: [
			"listUsFilter",
			startDate,
			endDate,
			cost,
			listNumber,
			symbols,
		],
		queryFn: async ({ queryKey }) =>
			await getFilterData(
				null,
				(queryKey[1] as Dayjs).format("YYYY-MM-DD"),
				(queryKey[2] as Dayjs).format("YYYY-MM-DD"),
				queryKey[3] as number,
				queryKey[4] as string,
				queryKey[5] as string,
				"us"
			),
		enabled: false,
		refetchOnWindowFocus: false,
	});

	const {
		data: userRecords,
		isLoading: userRecordIsLoading,
		isFetching: userRecordIsFetching,
	} = useQuery({
		queryKey: ["userFilterRecords"],
		queryFn: async () => {
			const jwt = localStorage.getItem("token");
			if (!jwt) {
				return;
			}
			const payload = jwtDecode<JwtPayload & { telegram_id: number }>(
				jwt
			);
			const result = await listUserFilterRecords(
				null,
				payload.telegram_id
			);
			return result;
		},
		refetchOnWindowFocus: false,
	});

	const onSubmitHandler = async () => {
		if (cost <= 0) {
			toast.error("未輸入成本");
			return;
		}

		if (isNil(startDate) || isNil(endDate)) {
			toast.error("未輸入日期");
			return;
		}

		setSubmit(true);

		refetch();
	};

	const onSaveHandler = useCallback(
		async (data: Filter) => {
			if (isNil(userRecords)) {
				return;
			}
			if (userRecords.length === 20) {
				toast.warn("最多只能儲存20個記錄");
				return;
			}

			const jwt = localStorage.getItem("token");
			if (!jwt) {
				navigate("/");
				return;
			}
			const payload = jwtDecode<JwtPayload & { telegram_id: number }>(
				jwt
			);
			await saveFilterRecord(
				null,
				payload.telegram_id,
				data.symbol.toLowerCase(),
				data.prob,
				startDate?.format("YYYY-MM-DD") ?? "",
				endDate?.format("YYYY-MM-DD") ?? "",
				cost
			);
			queryClient.invalidateQueries({
				predicate: ({ queryKey }) =>
					queryKey[0] === "userFilterRecords",
			});
		},
		[cost, endDate, navigate, queryClient, startDate, userRecords]
	);

	const columns: GridColDef<Filter>[] = useMemo(() => {
		return [
			{
				field: "action",
				headerName: "Save",
				flex: 0.4,
				minWidth: 50,
				renderCell: (params) => {
					if (isNil(userRecords)) {
						return null;
					}
					const isRecordSaved = userRecords.find(
						(e) =>
							e.symbol === params.row.symbol.toLowerCase() &&
							e.start_date === startDate?.format("YYYY-MM-DD") &&
							e.end_date === endDate?.format("YYYY-MM-DD") &&
							e.probability <= params.row.prob + 0.001 &&
							e.probability >= params.row.prob - 0.001
					);
					if (isNil(isRecordSaved)) {
						return (
							<IconButton
								onClick={() => onSaveHandler(params.row)}
							>
								<Icon color="primary">save</Icon>
							</IconButton>
						);
					}
					return null;
				},
			},
			{ field: "code", headerName: "Code", flex: 0.7, minWidth: 180 },
			{
				field: "buy_type",
				headerName: "Buy Type",
				flex: 0.4,
				minWidth: 80,
			},
			{ field: "status", headerName: "狀態", flex: 0.4, minWidth: 80 },
			{
				field: "symbol",
				headerName: "Symbol",
				flex: 0.4,
				minWidth: 80,
				valueFormatter: (params) => params.value.toUpperCase(),
			},
			{
				field: "total_years",
				headerName: "有效年份",
				flex: 0.4,
				minWidth: 80,
			},
			{
				field: "prob",
				headerName: "機率",
				flex: 0.4,
				minWidth: 80,
				valueFormatter: (params) =>
					`${(params.value * 100).toFixed(2)}%`,
			},
			{
				field: "now_price",
				headerName: "現價",
				flex: 0.4,
				minWidth: 100,
				valueFormatter: (params) =>
					params.value === 0 ? "" : params.value.toFixed(4),
			},
			{
				field: "start_price",
				headerName: "開始價",
				flex: 0.4,
				minWidth: 100,
				valueFormatter: (params) =>
					params.value === 0 ? "" : params.value.toFixed(4),
			},
			{
				field: "target",
				headerName: "目標價",
				flex: 0.7,
				minWidth: 100,
				renderCell: (params) =>
					params.value.up === 0 && params.value.down === 0 ? (
						<></>
					) : (
						<div>
							<div className="MuiDataGrid-cellContent">
								{params.value.up.toFixed(4)}
							</div>
							<div className="MuiDataGrid-cellContent">
								{params.value.down.toFixed(4)}
							</div>
						</div>
					),
			},
			{
				field: "volatility",
				headerName: "波幅",
				flex: 0.4,
				minWidth: 80,
				sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
				valueFormatter: (params) =>
					`${(params.value * 100).toFixed(2)}%`,
			},
			{
				field: "current_volatility",
				headerName: "暫時波幅",
				flex: 0.4,
				minWidth: 80,
				renderCell: (params) => (
					<div>
						<div className="MuiDataGrid-cellContent">
							{`${(params.value.up * 100).toFixed(2)}%`}
						</div>
						<div className="MuiDataGrid-cellContent">
							{`${(params.value.down * 100).toFixed(2)}%`}
						</div>
					</div>
				),
			},
			{
				field: "expiration",
				headerName: "到期日",
				flex: 0.5,
				minWidth: 100,
			},
			{
				field: "daysLeft",
				headerName: "距離到期日",
				flex: 0.4,
				minWidth: 100,
				renderCell: (params) =>
					params.row.expiration === "" ? (
						<></>
					) : (
						<div>
							{dayjs(params.row.expiration).diff(dayjs(), "day")}
						</div>
					),
			},
			{
				field: "strategy",
				headerName: "策略",
				flex: 0.7,
				minWidth: 220,
				renderCell: (params) => (
					<div>
						<div className="MuiDataGrid-cellContent">
							SC:{params.value.sc.strike}(
							{params.value.sc.price.toFixed(2)}) LC:
							{params.value.lc.strike}(
							{params.value.lc.price.toFixed(2)})
						</div>
						<div className="MuiDataGrid-cellContent">
							LP:{params.value.lp.strike}(
							{params.value.lp.price.toFixed(2)}) SP:
							{params.value.sp.strike}(
							{params.value.sp.price.toFixed(2)})
						</div>
					</div>
				),
			},
			{
				field: "limit_price",
				headerName: "掛盤價",
				flex: 0.4,
				minWidth: 100,
				valueFormatter: (params) =>
					params.value === 0 ? "" : params.value.toFixed(2),
			},
			{
				field: "quantity",
				headerName: "Set數",
				flex: 0.4,
				minWidth: 80,
				renderCell: (params) => (
					<div>
						<div className="MuiDataGrid-cellContent">
							{params.value.up}
						</div>
						<div className="MuiDataGrid-cellContent">
							{params.value.down}
						</div>
					</div>
				),
			},
			{
				field: "commission",
				headerName: "手續費",
				flex: 0.4,
				minWidth: 80,
				renderCell: (params) => (
					<div>
						<div className="MuiDataGrid-cellContent">
							{(params.row.quantity.up * commission * 2).toFixed(
								3
							)}
						</div>
						<div className="MuiDataGrid-cellContent">
							{(
								params.row.quantity.down *
								commission *
								2
							).toFixed(3)}
						</div>
					</div>
				),
			},
			{
				field: "take_profit_target",
				headerName: "TakeProfit",
				flex: 0.4,
				minWidth: 100,
				renderCell: (params) => (
					<div>
						<div className="MuiDataGrid-cellContent">
							{params.value.up}
						</div>
						<div className="MuiDataGrid-cellContent">
							{params.value.down}
						</div>
					</div>
				),
			},
			{
				field: "win_amount",
				headerName: "淨贏金額(USD$)",
				flex: 0.4,
				minWidth: 120,
				renderCell: (params) => (
					<div>
						<div className="MuiDataGrid-cellContent">
							{(
								params.value.up -
								params.row.quantity.up * commission * 2
							).toFixed(2)}
						</div>
						<div className="MuiDataGrid-cellContent">
							{(
								params.value.down -
								params.row.quantity.down * commission * 2
							).toFixed(2)}
						</div>
					</div>
				),
			},
			{
				field: "odds",
				headerName: "淨贏賠率",
				flex: 0.4,
				minWidth: 100,
				renderCell: (params) => (
					<div>
						<div className="MuiDataGrid-cellContent">
							{(
								(params.row.win_amount.up -
									params.row.quantity.up * commission * 2) /
								cost
							).toFixed(2)}
						</div>
						<div className="MuiDataGrid-cellContent">
							{(
								(params.row.win_amount.down -
									params.row.quantity.down * commission * 2) /
								cost
							).toFixed(2)}
						</div>
					</div>
				),
			},
			{
				field: "average_odds",
				headerName: "平均賠率",
				flex: 0.4,
				minWidth: 100,
				valueGetter: (params) =>
					(
						((params.row.win_amount.up -
							params.row.quantity.up * commission * 2) /
							cost +
							(params.row.win_amount.down -
								params.row.quantity.down * commission * 2) /
								cost) /
						2
					).toFixed(2),
				sortComparator: (v1, v2) => parseFloat(v1) - parseFloat(v2),
			},
			{
				field: "success",
				headerName: "過去中code次數",
				flex: 0.4,
				minWidth: 120,
			},
			{
				field: "total",
				headerName: "有效記錄",
				flex: 0.4,
				minWidth: 100,
			},
		];
	}, [commission, cost, endDate, onSaveHandler, startDate, userRecords]);

	const processedData = useMemo(() => {
		if (isNil(data)) {
			return [];
		}

		return data.map((e) => ({
			...e,
			id: uniqueId(),
		}));
	}, [data]);

	const isNotFriday = (date: Dayjs) => {
		const day = date.day();

		return day < 5 || day > 5;
	};

	return (
		<>
			<Grid container justifyContent={"center"} sx={{ mt: 3 }}>
				<Grid item>
					<Typography variant="h3">
						美股篩選(最多只接受month over 4)
					</Typography>
				</Grid>
			</Grid>

			<Grid
				container
				spacing={2}
				justifyContent={"center"}
				sx={{ mt: 3 }}
			>
				{listNumber === "自選" && (
					<Grid item display="flex" justifyContent="center">
						<TextField
							label="Symbols"
							value={symbols}
							onChange={(e) => setSymbols(e.target.value)}
							disabled={submit}
						/>
					</Grid>
				)}
				<Grid item display="flex" justifyContent="center">
					<Autocomplete
						disabled={submit}
						disableClearable={true}
						value={listNumber}
						onChange={(_, newValue) => setListNumber(newValue)}
						options={["1", "2", "3", "自選"]}
						renderInput={(params) => (
							<TextField {...params} label="list" />
						)}
						sx={{ width: 150 }}
					/>
				</Grid>
				<Grid item display="flex" justifyContent="center">
					<TextField
						label="成本(USD$)"
						value={cost}
						onChange={(e) => {
							const val = e.target.value;
							if (val === "") {
								setCost(0);
								return;
							}
							setCost(parseFloat(val));
						}}
						disabled={submit}
					/>
				</Grid>
				<Grid item display="flex" justifyContent="center">
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							value={startDate}
							onChange={(newValue) => setStartDate(newValue)}
							disabled={submit}
						/>
					</LocalizationProvider>
				</Grid>
				<Grid item display="flex" justifyContent="center">
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							value={endDate}
							onChange={(newValue) => setEndDate(newValue)}
							disabled={submit}
							shouldDisableDate={isNotFriday}
						/>
					</LocalizationProvider>
				</Grid>
				<Grid item display="flex" justifyContent="center">
					<TextField
						label="手續費(USD$)"
						value={commission}
						onChange={(e) => {
							const val = e.target.value;
							if (val === "") {
								setCommission(0);
								return;
							}
							setCommission(parseFloat(val));
						}}
						disabled={submit}
						type="number"
					/>
				</Grid>

				<Grid item>
					{submit ? (
						<Button
							style={{ height: 55 }}
							onClick={() => window.location.reload()}
							variant="contained"
						>
							重置
						</Button>
					) : (
						<Button
							style={{ height: 55 }}
							type="submit"
							variant="contained"
							onClick={onSubmitHandler}
						>
							美股篩選
						</Button>
					)}
				</Grid>
			</Grid>

			<Grid container justifyContent={"center"} sx={{ mt: 5 }}>
				<DataGridPremium
					rows={processedData}
					columns={columns}
					autoHeight
					loading={
						isFetching ||
						isLoading ||
						userRecordIsLoading ||
						userRecordIsFetching
					}
				/>
			</Grid>
		</>
	);
};

export default FilterPage;
