import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
	DividendForm,
	DividendInfo,
	DividendTable,
} from "../../components/DividendID";
import { DividendIdData } from "../../schemas/Dividend";
import { getDividendId } from "../../utils/axios/dividend";

const DividendIdPage = () => {
	const [data, setData] = useState<DividendIdData>();
	const [fileDate, setFileDate] = useState("");
	const [strikeAmount, setStrikeAmount] = useState("20");
	const [update, setUpdate] = useState("上交易日報告");

	const { id } = useParams();

	const getData = useCallback(async () => {
		const jwt = localStorage.getItem("token");
		const res = await getDividendId(jwt, id);
		setData(res);
		setFileDate(res["file_date"]);
	}, [id]);

	useEffect(() => {
		getData();
	}, [getData]);

	return (
		<>
			{_.isEmpty(data) ? (
				<Box sx={{ display: "flex", mt: 10, justifyContent: "center" }}>
					<CircularProgress />
				</Box>
			) : (
				<>
					<Grid container justifyContent={"center"} sx={{ mt: 3 }}>
						<Grid item>
							<Typography variant="h3">
								{data.name} {id} 期權息率表
							</Typography>
						</Grid>
					</Grid>
					<DividendInfo data={data} fileDate={fileDate} />
					<DividendForm
						strikeAmount={strikeAmount}
						setStrikeAmount={setStrikeAmount}
						update={update}
						setUpdate={setUpdate}
					/>
					{Object.keys(data).map((key) =>
						key[4] === "-" ? (
							<DividendTable
								key={key}
								date={key}
								data={data[key]}
								strikeAmount={strikeAmount}
								nowPrice={data["now_price"]}
							/>
						) : null
					)}
				</>
			)}
		</>
	);
};

export default DividendIdPage;
