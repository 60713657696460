import { Box, Card, CardContent, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { routes } from "../../routes/routes";
import { CustomJwtPayload } from "../../components/Header/header";
import jwtDecode from "jwt-decode";
import { isNil } from "lodash";

const HomePage = () => {
	const jwt = localStorage.getItem("token");
	let payload: CustomJwtPayload;
	if (!jwt) payload = { telegram_id: 0, phone: "" };
	else payload = jwtDecode<CustomJwtPayload>(jwt);

	return (
		<>
			<Typography textAlign="center" variant="h3" sx={{ mt: 5 }}>
				歡迎來到QuantRaiser Web
			</Typography>

			<Box
				sx={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "space-evenly",
					mt: 10,
				}}
			>
				{routes.map((route) => {
					if (
						((route.admin &&
							[1125124970, 352264299].includes(
								payload.telegram_id
							)) ||
							(!route.admin && route.showHeader)) &&
						route.title !== "主頁"
					)
						return isNil(route.children) ? (
							route.redirect ? (
								<Box sx={{ width: 300, flex: 0.5, m: 5 }}>
									<Card variant="outlined">
										<CardContent sx={{ height: 200 }}>
											<a
												href={route.redirect}
												target="_blank"
											>
												<Typography variant="h6">
													{route.title}
												</Typography>
											</a>
										</CardContent>
									</Card>
								</Box>
							) : (
								<NavLink to={route.path} key={route.path}>
									<Box sx={{ width: 300, flex: 0.5, m: 5 }}>
										<Card variant="outlined">
											<CardContent sx={{ height: 200 }}>
												<Typography
													textAlign="center"
													variant="h4"
												>
													{route.title}
												</Typography>
											</CardContent>
										</Card>
									</Box>
								</NavLink>
							)
						) : (
							route.children.map((nestedRoute) => (
								<NavLink
									to={`${route.path}${nestedRoute.path}`}
									key={`${route.path}${nestedRoute.path}`}
								>
									<Box sx={{ width: 300, flex: 0.5, m: 5 }}>
										<Card variant="outlined">
											<CardContent sx={{ height: 200 }}>
												<Typography
													textAlign="center"
													variant="h4"
												>
													{nestedRoute.title}
												</Typography>
											</CardContent>
										</Card>
									</Box>
								</NavLink>
							))
						);
					else return null;
				})}
			</Box>
		</>
	);
};

export default HomePage;
