import DisclaimerPage from "../containers/DisclaimerPage";
import DividendIDPage from "../containers/DividendIDPage";
import ExcelPage from "../containers/ExcelPage";
import FuturesMoneyFlowPage from "../containers/FuturesMoneyFlowPage";
import HomePage from "../containers/HomePage";
import MoneyFlowHistoryPage from "../containers/MoneyFlowHistoryPage";
import MoneyFlowListPage from "../containers/MoneyFlowListPage";
import MoneyFlowPage from "../containers/MoneyFlowPage";
import OptionTablePage from "../containers/OptionTablePage";
import QuotesPage from "../containers/QuotesPage/quotesPage";
import IndicatorPage from "../containers/IndicatorPage";
import StableWinPage from "../containers/StableWinPage";
import ConstituentsPage from "../containers/ConstituentsPage";
import FilterPage from "../containers/FilterPage/filterPage";
import MoneyFlowUsPage from "../containers/MoneyFlowUsPage";
import ExcelMonthPage from "../containers/ExcelMonthPage";
import { FilterRecordPage } from "../containers/FilterPage";
import DividendPage from "../containers/DividendPage";

interface Route {
	container: () => JSX.Element;
	path: string;
	showHeader: boolean;
	title?: string;
	children?: Route[];
	admin?: boolean;
	redirect?: string;
}

export const routes: Route[] = [
	{
		container: HomePage,
		path: "/home",
		showHeader: true,
		title: "主頁",
	},
	{
		container: StableWinPage,
		path: "/stable-win",
		showHeader: true,
		title: "Stable Win",
		admin: true,
	},
	{
		container: FuturesMoneyFlowPage,
		path: "/moneyflow/futures",
		showHeader: true,
		title: "期指市場資金流",
		redirect: "https://app.quantraiser.info/moneyflow/futures",
	},
	{
		container: MoneyFlowPage,
		path: "/moneyflow",
		showHeader: true,
		title: "期權市場資金流",
	},
	{
		container: MoneyFlowUsPage,
		path: "/moneyflow/us",
		showHeader: true,
		title: "期權市場資金流(美股)",
	},
	{
		container: MoneyFlowListPage,
		path: "/moneyflow/list",
		showHeader: true,
		title: "期權市場資金流一覽",
	},
	{
		container: MoneyFlowHistoryPage,
		path: "/moneyflow/history",
		showHeader: true,
		title: "期權資金流歷史",
	},
	// {
	// 	container: TakeProfitTargetPage,
	// 	path: "/take-profit-target",
	// 	showHeader: true,
	// 	title: "策略對焦",
	// 	children: [
	// 		{
	// 			container: DebitCallSpreadPage,
	// 			path: "/dcs",
	// 			showHeader: true,
	// 			title: "Debit Call Spread",
	// 		},
	// 		{
	// 			container: DebitPutSpreadPage,
	// 			path: "/dps",
	// 			showHeader: true,
	// 			title: "Debit Put Spread",
	// 		},
	// 		{
	// 			container: ReverseIronCondorPage,
	// 			path: "/ric",
	// 			showHeader: true,
	// 			title: "Reverse Iron Condor",
	// 		},
	// 	],
	// },
	{
		container: DividendPage,
		path: "/dividend",
		showHeader: true,
		title: "期權息率表",
	},
	{
		container: DividendIDPage,
		path: "/dividend/:id",
		showHeader: false,
	},
	{
		container: QuotesPage,
		path: "/quotes",
		showHeader: true,
		title: "期權報價表",
	},
	{
		container: ExcelPage,
		path: "/excel",
		showHeader: true,
		title: "Data 對數表",
		redirect: "https://app.quantraiser.info/excel",
		// children: [
		// 	{
		// 		container: ExcelPage,
		// 		path: "/excel",
		// 		showHeader: true,
		// 		title: "Data 對數表",
		// 	},
		// 	{
		// 		container: ExcelMonthPage,
		// 		path: "/month",
		// 		showHeader: true,
		// 		title: "Data 對數表(by month)",
		// 		admin: true,
		// 	},
		// ],
	},
	{
		container: ExcelMonthPage,
		path: "/excel/month",
		showHeader: true,
		title: "Data 對數表(by month)",
		admin: true,
	},
	{
		container: IndicatorPage,
		path: "/indicator",
		showHeader: true,
		title: "技術指標機率表",
	},
	{
		container: OptionTablePage,
		path: "/table",
		showHeader: true,
		title: "期權計價表",
	},
	{
		container: FilterPage,
		path: "",
		showHeader: true,
		title: "美股篩選",
		redirect: "https://app.quantraiser.info/stock-filter",
	},
	{
		container: ConstituentsPage,
		path: "/constituents",
		showHeader: true,
		title: "指數成份股",
	},
	{
		container: DisclaimerPage,
		path: "/disclaimer",
		showHeader: true,
		title: "免責聲明",
	},
];
