import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HeaderBar from "./components/Header";
import LoginPage from "./containers/LoginPage";
import ProtectedRoute from "./routes/protectedRoute";
import { routes } from "./routes/routes";
import { isNil } from "lodash";

const App = () => {
	return (
		<>
			<HeaderBar />
			<Routes>
				{routes.map((route) =>
					isNil(route.children) ? (
						<Route
							path={route.path}
							element={
								<ProtectedRoute>
									<route.container />
								</ProtectedRoute>
							}
							key={route.path}
						/>
					) : (
						route.children.map((nestedRoute) => (
							<Route
								path={`${route.path}${nestedRoute.path}`}
								element={
									<ProtectedRoute>
										<nestedRoute.container />
									</ProtectedRoute>
								}
								key={`${route.path}${nestedRoute.path}`}
							/>
						))
					)
				)}
				<Route path="/login" element={<LoginPage />} />
				<Route path="*" element={<Navigate to="/home" replace />} />
			</Routes>
			<ToastContainer />
		</>
	);
};

export default App;
