import { Box, Grid, Modal, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import {
	ArcElement,
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	Title,
	Tooltip,
} from "chart.js";
import ChartDataLabels, { Context } from "chartjs-plugin-datalabels";
import { MoneyFlowForm } from "../../components/MoneyFlow";
import { MoneyFlowReportData } from "../../schemas/MoneyFlow";
import {
	getAllHkBarRt,
	getAllHkBarYtd,
	getAllUsBarRt,
	getHkBarRt,
	getHkBarYtd,
	getHkPercentDby,
	getHkPercentRt,
	getHkPercentYtd,
	getHkValidSymbol,
	getUsBarRt,
	getUsPercentRt,
	getUsValidSymbol,
} from "../../utils/axios/moneyFlow";
import _ from "lodash";

ChartJS.register(
	ArcElement,
	BarElement,
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
	Title
);

const MoneyFlowPage = () => {
	const [hkValidSymbols, setHkValidSymbols] = useState<string[]>([]);
	const [usValidSymbols, setUsValidSymbols] = useState<string[]>([]);
	const [symbol, setSymbol] = useState("");
	const [update, setUpdate] = useState("上交易日報告");
	const [expiryDate, setExpiryDate] = useState("");
	const [innerPercent, setInnerPercent] = useState<number[]>([]);
	const [outerPercent, setOuterPercent] = useState<number[]>([]);
	const [updateTime, setUpdateTime] = useState("");
	const [barLabels, setBarLabels] = useState<string[]>([]);
	const [barCallIn, setBarCallIn] = useState<number[]>([]);
	const [barCallTime, setBarCallTime] = useState<number[]>([]);
	const [barPutIn, setBarPutIn] = useState<number[]>([]);
	const [barPutTime, setBarPutTime] = useState<number[]>([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [barModalOpen, setBarModalOpen] = useState(false);
	const [allBarLabels, setAllBarLabels] = useState<string[]>([]);
	const [allBarCallIn, setAllBarCallIn] = useState<number[]>([]);
	const [allBarCallTime, setAllBarCallTime] = useState<number[]>([]);
	const [allBarPutIn, setAllBarPutIn] = useState<number[]>([]);
	const [allBarPutTime, setAllBarPutTime] = useState<number[]>([]);

	const percentData = {
		labels: ["Call價內", "Call時間", "Put價內", "Put時間"],
		datasets: [
			{
				label: "Realtime",
				data: outerPercent,
				backgroundColor: [
					"rgba(0, 115, 230, 1)",
					"rgba(237, 125, 49, 1)",
					"rgba(0, 176, 80, 1)",
					"rgba(122, 0, 204, 1)",
				],
				borderColor: "rgba(255, 255, 255, 1)",
				borderWidth: 1,
				weight: 1,
			},
			{
				label: "Yesterday",
				data: innerPercent,
				backgroundColor: [
					"rgba(0, 115, 230, 0.5)",
					"rgba(237, 125, 49, 0.5)",
					"rgba(0, 176, 80, 0.5)",
					"rgba(122, 0, 204, 0.5)",
				],
				borderColor: "rgba(255, 255, 255, 1)",
				borderWidth: 1,
				weight: 0.7,
			},
		],
	};

	const percentOptions = {
		animation: {
			duration: 0,
		},
		plugins: {
			datalabels: {
				color: "#000000",
				font: { size: 15 },
				formatter: (value: number, context: Context) =>
					context.dataset.label === "Realtime"
						? `${Math.round(
								(value /
									outerPercent.reduce((a, b) => a + b, 0)) *
									100
						  )}%`
						: `${Math.round(
								(value /
									innerPercent.reduce((a, b) => a + b, 0)) *
									100
						  )}%`,
			},
			legend: {
				position: "right" as const,
				align: "end" as const,
				labels: {
					boxWidth: 40,
					font: { size: 20 },
					padding: 20,
				},
			},
			title: {
				display: true,
				text: [`${symbol} 期權好淡變化`, `(合約月份: ${expiryDate})`],
				font: { size: 30 },
			},
		},
	};

	const barData = {
		labels: barLabels,
		datasets: [
			{
				label: "Call價內",
				data: barCallIn,
				borderColor: "rgba(255, 255, 255, 1)",
				backgroundColor: "rgba(0, 115, 230, 1)",
				barPercentage: 0.5,
			},
			{
				label: "Call時間",
				data: barCallTime,
				borderColor: "rgba(255, 255, 255, 1)",
				backgroundColor: "rgba(237, 125, 49, 1)",
				barPercentage: 0.5,
			},
			{
				label: "Put價內",
				data: barPutIn,
				borderColor: "rgba(255, 255, 255, 1)",
				backgroundColor: "rgba(0, 176, 80, 1)",
				barPercentage: 0.5,
			},
			{
				label: "Put時間",
				data: barPutTime,
				borderColor: "rgba(255, 255, 255, 1)",
				backgroundColor: "rgba(122, 0, 204, 1)",
				barPercentage: 0.5,
			},
		],
	};

	const barAllData = {
		labels: allBarLabels,
		datasets: [
			{
				label: "Call價內",
				data: allBarCallIn,
				borderColor: "rgba(255, 255, 255, 1)",
				backgroundColor: "rgba(0, 115, 230, 1)",
			},
			{
				label: "Call時間",
				data: allBarCallTime,
				borderColor: "rgba(255, 255, 255, 1)",
				backgroundColor: "rgba(237, 125, 49, 1)",
			},
			{
				label: "Put價內",
				data: allBarPutIn,
				borderColor: "rgba(255, 255, 255, 1)",
				backgroundColor: "rgba(0, 176, 80, 1)",
			},
			{
				label: "Put時間",
				data: allBarPutTime,
				borderColor: "rgba(255, 255, 255, 1)",
				backgroundColor: "rgba(122, 0, 204, 1)",
			},
		],
	};

	const barOptions = {
		animation: {
			duration: 0,
		},
		indexAxis: "y" as const,
		interaction: {
			intersect: false,
			mode: "index" as const,
		},
		scales: {
			x: {
				stacked: true,
			},
			y: {
				stacked: true,
			},
		},
		plugins: {
			legend: {
				position: "right" as const,
				align: "end" as const,
				fullWidth: false,
				labels: {
					font: {
						size: 20,
					},
				},
				padding: 20,
			},
			maintainAspectRatio: false,
			title: {
				display: true,
				text: [`${symbol} 期權資金流`, `(合約月份: ${expiryDate})`],
				position: "top" as const,
				font: {
					size: 30,
				},
			},
		},
	};

	const barAllOptions = {
		animation: {
			duration: 0,
		},
		indexAxis: "y" as const,
		interaction: {
			intersect: false,
			mode: "index" as const,
		},
		scales: {
			x: {
				stacked: true,
			},
			y: {
				stacked: true,
			},
		},
		plugins: {
			legend: {
				position: "top" as const,
				align: "center" as const,
				fullWidth: false,
				labels: {
					font: {
						size: 20,
					},
				},
				padding: 20,
			},
			maintainAspectRatio: false,
			title: {
				display: true,
				text: [`${symbol} 期權資金流`, `(合約月份: ${expiryDate})`],
				position: "top" as const,
				font: {
					size: 30,
				},
			},
		},
	};

	const getValidSymbols = useCallback(async () => {
		const jwt = localStorage.getItem("token");
		const hkValidSymbols = await getHkValidSymbol(jwt);
		const usValidSymbols = await getUsValidSymbol(jwt);
		setHkValidSymbols(hkValidSymbols);
		setUsValidSymbols(usValidSymbols);
	}, []);

	const getRtHkData = useCallback(async () => {
		if (expiryDate !== "") {
			const jwt = localStorage.getItem("token");
			const ytdReport = await getHkPercentYtd(jwt);
			const ytdDateReport = ytdReport[expiryDate];
			const ytdSymbolReport: MoneyFlowReportData =
				ytdDateReport[symbol as keyof typeof ytdDateReport];

			const { call_in, call_time, put_in, put_time } =
				await getHkPercentRt(symbol, expiryDate, jwt);
			setOuterPercent([call_in, call_time, put_in, put_time]);
			setInnerPercent([
				ytdSymbolReport.call_in,
				ytdSymbolReport.call_time,
				ytdSymbolReport.put_in,
				ytdSymbolReport.put_time,
			]);
			const rtBar = await getHkBarRt(symbol, expiryDate, jwt);
			setBarLabels(Object.keys(rtBar).reverse());
			setBarCallIn(_.map(rtBar, (bar) => bar["call_in"]).reverse());
			setBarCallTime(_.map(rtBar, (bar) => bar["call_time"]).reverse());
			setBarPutIn(_.map(rtBar, (bar) => bar["put_in"]).reverse());
			setBarPutTime(_.map(rtBar, (bar) => bar["put_time"]).reverse());
		}
	}, [expiryDate, symbol]);

	const getYtdHkData = useCallback(async () => {
		if (expiryDate !== "") {
			const jwt = localStorage.getItem("token");
			const ytdReport = await getHkPercentYtd(jwt);
			const ytdDateReport = ytdReport[expiryDate];
			const ytdSymbolReport: MoneyFlowReportData =
				ytdDateReport[symbol as keyof typeof ytdDateReport];
			const dbyReport = await getHkPercentDby(jwt);
			const dbyDateReport = dbyReport[expiryDate];
			const dbySymbolReport: MoneyFlowReportData =
				dbyDateReport[symbol as keyof typeof dbyDateReport];
			setOuterPercent([
				ytdSymbolReport.call_in,
				ytdSymbolReport.call_time,
				ytdSymbolReport.put_in,
				ytdSymbolReport.put_time,
			]);
			setInnerPercent([
				dbySymbolReport.call_in,
				dbySymbolReport.call_time,
				dbySymbolReport.put_in,
				dbySymbolReport.put_time,
			]);
			const ytdBar = await getHkBarYtd(symbol, expiryDate, jwt);
			setBarLabels(Object.keys(ytdBar).reverse());
			setBarCallIn(_.map(ytdBar, (bar) => bar["call_in"]).reverse());
			setBarCallTime(_.map(ytdBar, (bar) => bar["call_time"]).reverse());
			setBarPutIn(_.map(ytdBar, (bar) => bar["put_in"]).reverse());
			setBarPutTime(_.map(ytdBar, (bar) => bar["put_time"]).reverse());
		}
	}, [expiryDate, symbol]);

	const getAllHkBar = useCallback(async () => {
		const jwt = localStorage.getItem("token");
		let allBar;
		if (update === "上交易日報告") {
			allBar = await getAllHkBarYtd(symbol, expiryDate, jwt);
		} else {
			allBar = await getAllHkBarRt(symbol, expiryDate, jwt);
		}
		setAllBarLabels(Object.keys(allBar).reverse());
		setAllBarCallIn(_.map(allBar, (bar) => bar["call_in"]).reverse());
		setAllBarCallTime(_.map(allBar, (bar) => bar["call_time"]).reverse());
		setAllBarPutIn(_.map(allBar, (bar) => bar["put_in"]).reverse());
		setAllBarPutTime(_.map(allBar, (bar) => bar["put_time"]).reverse());
	}, [expiryDate, symbol, update]);

	const getAllUsBar = useCallback(async () => {
		const jwt = localStorage.getItem("token");
		const allBar = await getAllUsBarRt(symbol, expiryDate, jwt);
		setAllBarLabels(Object.keys(allBar).reverse());
		setAllBarCallIn(_.map(allBar, (bar) => bar["call_in"]).reverse());
		setAllBarCallTime(_.map(allBar, (bar) => bar["call_time"]).reverse());
		setAllBarPutIn(_.map(allBar, (bar) => bar["put_in"]).reverse());
		setAllBarPutTime(_.map(allBar, (bar) => bar["put_time"]).reverse());
	}, [expiryDate, symbol]);

	const getRtUsData = useCallback(async () => {
		if (expiryDate !== "") {
			const jwt = localStorage.getItem("token");
			const { call_in, call_time, put_in, put_time } =
				await getUsPercentRt(symbol, expiryDate, jwt);
			setOuterPercent([call_in, call_time, put_in, put_time]);
			const rtBar = await getUsBarRt(symbol, expiryDate, jwt);
			setBarLabels(Object.keys(rtBar).reverse());
			setBarCallIn(_.map(rtBar, (bar) => bar["call_in"]).reverse());
			setBarCallTime(_.map(rtBar, (bar) => bar["call_time"]).reverse());
			setBarPutIn(_.map(rtBar, (bar) => bar["put_in"]).reverse());
			setBarPutTime(_.map(rtBar, (bar) => bar["put_time"]).reverse());
		}
	}, [expiryDate, symbol]);

	useEffect(() => {
		getValidSymbols();
	}, [getValidSymbols]);

	useEffect(() => {
		if (_.includes(hkValidSymbols, symbol) && update === "上交易日報告")
			getYtdHkData();
	}, [getYtdHkData, hkValidSymbols, symbol, update]);

	useEffect(() => {
		if (_.includes(hkValidSymbols, symbol) && update === "持續更新") {
			getRtHkData();
			setUpdateTime(dayjs().format("YYYY-MM-DD HH:mm:ss"));
		}
	}, [getRtHkData, update, outerPercent, hkValidSymbols, symbol]);

	useEffect(() => {
		if (_.includes(usValidSymbols, symbol.toUpperCase())) {
			getRtUsData();
			setUpdateTime(dayjs().format("YYYY-MM-DD HH:mm:ss"));
		}
	}, [usValidSymbols, symbol, getRtUsData, outerPercent]);

	return (
		<>
			<Grid container justifyContent={"center"} sx={{ mt: 3 }}>
				<Grid item>
					<Typography variant="h3">期權市場資金流</Typography>
				</Grid>
			</Grid>

			<Modal open={modalOpen} onClose={() => setModalOpen(false)}>
				<Box
					sx={{
						position: "absolute" as "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: 400,
						bgcolor: "background.paper",
						border: "2px solid #fff",
						borderRadius: "10px",
						boxShadow: 24,
						p: 4,
						overflow: "scroll",
						height: 500,
					}}
				>
					<Typography variant="h4">港股:</Typography>
					{hkValidSymbols.map((symbol) => (
						<Typography key={symbol}>{symbol}</Typography>
					))}
					<Typography variant="h4">美股:</Typography>
					{usValidSymbols.map((symbol) => (
						<Typography key={symbol}>{symbol}</Typography>
					))}
				</Box>
			</Modal>
			<Modal open={barModalOpen} onClose={() => setBarModalOpen(false)}>
				<Box
					sx={{
						position: "absolute" as "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: { xs: 400, sm: 1200 },
						bgcolor: "background.paper",
						border: "2px solid #fff",
						borderRadius: "10px",
						boxShadow: 24,
						p: 4,
						overflow: "scroll",
						height: { xs: 400, sm: 800 },
					}}
				>
					<Bar
						data={barAllData}
						options={barAllOptions}
						height={480}
						width={200}
					/>
				</Box>
			</Modal>
			<Grid
				container
				sx={{ mt: 4, flexDirection: { xs: "column", sm: "row" } }}
			>
				<Grid item xs={2} sx={{ pt: { sm: 15 } }}>
					<MoneyFlowForm
						symbol={symbol}
						setSymbol={setSymbol}
						update={update}
						setUpdate={setUpdate}
						hkValidSymbols={hkValidSymbols}
						usValidSymbols={usValidSymbols}
						expiryDate={expiryDate}
						setExpiryDate={setExpiryDate}
						updateTime={updateTime}
						setModalOpen={setModalOpen}
					/>
				</Grid>

				{!_.isEmpty(outerPercent) &&
					!_.isEmpty(barLabels) &&
					!_.isEmpty(barCallIn) &&
					!_.isEmpty(barCallTime) &&
					!_.isEmpty(barPutIn) &&
					!_.isEmpty(barPutTime) && (
						<Grid item xs={10} sx={{ maxWidth: { xs: "100%" } }}>
							<Grid
								container
								sx={{
									flexDirection: { xs: "column", sm: "row" },
								}}
							>
								<Grid
									item
									xs={6}
									sx={{ maxWidth: { xs: "100%", sm: "50%" } }}
								>
									<Doughnut
										data={percentData}
										options={percentOptions}
										plugins={[ChartDataLabels]}
									/>
								</Grid>
								<Grid
									item
									xs={6}
									sx={{ maxWidth: { xs: "100%", sm: "50%" } }}
								>
									<Bar
										data={barData}
										onDoubleClick={async () => {
											if (
												_.includes(
													hkValidSymbols,
													symbol
												)
											) {
												await getAllHkBar();
											} else {
												await getAllUsBar();
											}
											setBarModalOpen(true);
										}}
										options={barOptions}
										height={480}
										width={500}
									/>
								</Grid>
							</Grid>
						</Grid>
					)}
			</Grid>
		</>
	);
};

export default MoneyFlowPage;
