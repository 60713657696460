import { joiResolver } from "@hookform/resolvers/joi";
import {
	Autocomplete,
	Button,
	Grid,
	TextField,
	Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import styled from "styled-components";
import { MoneyFlowFormSchema } from "../../schemas/MoneyFlow";
import {
	getHkExpiryDates,
	getUsExpiryDates,
} from "../../utils/axios/moneyFlow";

interface IMoneyFlowForm {
	symbol: string;
	setSymbol: React.Dispatch<React.SetStateAction<string>>;
	update: string;
	setUpdate: React.Dispatch<React.SetStateAction<string>>;
	hkValidSymbols: string[];
	usValidSymbols: string[];
	expiryDate: string;
	setExpiryDate: React.Dispatch<React.SetStateAction<string>>;
	updateTime: string;
	setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MoneyFlowForm = ({
	setSymbol,
	update,
	setUpdate,
	hkValidSymbols,
	usValidSymbols,
	expiryDate,
	setExpiryDate,
	updateTime,
	setModalOpen,
}: IMoneyFlowForm) => {
	const [submit, setSubmit] = useState<boolean>(false);
	const [expiryDateOptions, setExpiryDateOptions] = useState<string[]>([]);

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<{ symbol: string }>({
		defaultValues: { symbol: "" },
		resolver: joiResolver(MoneyFlowFormSchema),
	});

	const onSubmit = async (data: { symbol: string }) => {
		setSymbol(data.symbol.toLowerCase());
		const jwt = localStorage.getItem("token");
		if (hkValidSymbols.includes(data.symbol.toLowerCase())) {
			const expiryDates = await getHkExpiryDates(data.symbol, jwt);
			setExpiryDateOptions(expiryDates);
			setExpiryDate(expiryDates[0]);
			setSubmit(true);
		} else if (usValidSymbols.includes(data.symbol.toUpperCase())) {
			if (update === "上交易日報告") {
				toast.error("美股沒有上交易日報告");
				return;
			}
			const expiryDates = await getUsExpiryDates(jwt);
			setExpiryDateOptions(expiryDates);
			setExpiryDate(expiryDates[0]);
			setSubmit(true);
		} else {
			toast.error("不支援這個商品, 請選擇其他");
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container direction="column" spacing={5} sx={{ ml: 0 }}>
				<Grid item>
					<Button
						color="warning"
						variant="contained"
						sx={{ height: { xs: 40 } }}
						onClick={() => setModalOpen(true)}
					>
						查詢資金流名單
					</Button>
				</Grid>
				<Grid item>
					<Controller
						name="symbol"
						control={control}
						render={({ field }) => (
							<TextField
								label="股票/指數代號"
								variant="outlined"
								sx={{ width: 200 }}
								{...field}
							/>
						)}
					/>
				</Grid>
				<ErrorText>{errors.symbol?.message}</ErrorText>

				{!submit && (
					<Grid item>
						<Autocomplete
							disableClearable={true}
							value={update}
							onChange={(_, newValue) => setUpdate(newValue)}
							options={["持續更新", "上交易日報告"]}
							renderInput={(params) => (
								<TextField {...params} label="更新" />
							)}
							sx={{ width: 200 }}
						/>
					</Grid>
				)}

				{submit && (
					<Grid item>
						<Autocomplete
							disableClearable={true}
							value={expiryDate}
							onChange={(_, newValue) => setExpiryDate(newValue)}
							options={expiryDateOptions}
							renderInput={(params) => (
								<TextField {...params} label="結算日期" />
							)}
							sx={{ width: 200 }}
						/>
					</Grid>
				)}

				{submit && update === "持續更新" && (
					<Grid item>
						<Typography>更新時間:</Typography>
						<Typography>{updateTime}</Typography>
					</Grid>
				)}

				{!submit && (
					<Grid item>
						<Button
							style={{ height: 40 }}
							type="submit"
							variant="contained"
						>
							提交
						</Button>
					</Grid>
				)}

				{submit && (
					<Grid item>
						<Button
							style={{ height: 40 }}
							onClick={() => window.location.reload()}
							variant="contained"
						>
							重置
						</Button>
					</Grid>
				)}
			</Grid>
		</form>
	);
};

const ErrorText = styled.p`
	color: red;
	font-size: 20px;
	margin: 10px 0px 0px 40px;
	text-align: start;
`;

export default MoneyFlowForm;
