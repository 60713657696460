import { Autocomplete, Grid, TextField } from "@mui/material";

interface IDividendForm {
	strikeAmount: string;
	setStrikeAmount: React.Dispatch<React.SetStateAction<string>>;
	update: string;
	setUpdate: React.Dispatch<React.SetStateAction<string>>;
}

const DividendForm = ({
	strikeAmount,
	setStrikeAmount,
	update,
	setUpdate,
}: IDividendForm) => {
	return (
		<Grid container justifyContent="center" spacing={2} sx={{ mt: 5 }}>
			<Grid item>
				<Autocomplete
					value={strikeAmount}
					disableClearable
					onChange={(_, newValue) => setStrikeAmount(newValue)}
					options={["20", "全部"]}
					renderInput={(params) => (
						<TextField {...params} label="行使價數目" />
					)}
					sx={{ width: 100 }}
				/>
			</Grid>
			<Grid item>
				<Autocomplete
					value={update}
					disableClearable
					onChange={(_, newValue) => setUpdate(newValue)}
					options={["上交易日報告"]} // ["持續更新", "上交易日報告"]
					renderInput={(params) => (
						<TextField {...params} label="更新" />
					)}
					sx={{ width: 200 }}
				/>
			</Grid>
		</Grid>
	);
};

export default DividendForm;
