import { Grid, Typography } from "@mui/material";
import { DividendIdData } from "../../schemas/Dividend";

interface IDividendInfo {
	data: DividendIdData;
	fileDate: string;
}

const DividendInfo = ({ data, fileDate }: IDividendInfo) => {
	return (
		<Grid container sx={{ flexDirection: "column", mt: 5, ml: { sm: 10 } }}>
			<Grid item>
				<Typography variant="h4">相關資產: {data.name}</Typography>
			</Grid>
			<Grid item>
				<Typography variant="h4">
					相關資產價格: {data.now_price.toFixed(2)}
					{/* {data.now_price >= data.now_price_ytd ? "+" : ""}
					{(data.now_price - data.now_price_ytd).toFixed(2)} (
					{data.now_price >= data.now_price_ytd ? "+" : ""}
					{(
						(data.now_price - data.now_price_ytd) /
						data.now_price_ytd
					).toFixed(2)}
					%) */}
				</Typography>
			</Grid>
			{/* <Grid item>
				<Typography variant="h4">
					引申波幅(IV): {data.iv.toFixed(2)} (
					{data.iv >= data.iv_ytd ? "+" : ""}
					{((data.iv - data.iv_ytd) / data.iv_ytd).toFixed(2)}%)
				</Typography>
			</Grid> */}
			<Grid item>
				<Typography variant="h4">更新時間: {fileDate}</Typography>
			</Grid>
		</Grid>
	);
};

export default DividendInfo;
