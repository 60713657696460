import { Line } from "react-chartjs-2";
import {
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip,
	TooltipItem,
} from "chart.js";
import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import MoneyFlowHistoryForm from "../../components/MoneyFlowHistory";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const MoneyFlowHistoryPage = () => {
	const [label, setLabel] = useState<string[]>([]);
	const [callIn, setCallIn] = useState<number[]>([]);
	const [callTime, setCallTime] = useState<number[]>([]);
	const [putIn, setPutIn] = useState<number[]>([]);
	const [putTime, setPutTime] = useState<number[]>([]);

	const data = {
		labels: label,
		datasets: [
			{
				label: "Call 價內值",
				data: callIn,
				borderColor: "rgb(255, 99, 132)",
				backgroundColor: "rgba(255, 99, 132, 0.5)",
			},
			{
				label: "Call 時間值",
				data: callTime,
				borderColor: "rgb(53, 162, 235)",
				backgroundColor: "rgba(53, 162, 235, 0.5)",
			},
			{
				label: "Put 價內值",
				data: putIn,
				borderColor: "rgb(53, 255, 235)",
				backgroundColor: "rgba(53, 255, 235, 0.5)",
			},
			{
				label: "Put 時間值",
				data: putTime,
				borderColor: "rgb(255, 162, 235)",
				backgroundColor: "rgba(255, 162, 235, 0.5)",
			},
		],
	};

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: "top" as const,
			},
			title: {
				display: true,
				text: "期權資金流歷史",
			},
			tooltip: {
				enabled: true,
				mode: "index" as "index",
				intersect: false,
				position: "nearest" as "nearest",
				callbacks: {
					label: (tooltipItem: TooltipItem<"line">) => {
						let label = tooltipItem.dataset.label || "";
						label += ": ";
						label += tooltipItem.parsed.y.toFixed(2);
						label += "%";
						return label;
					},
				},
			},
		},
	};

	return (
		<>
			<Grid container justifyContent={"center"} sx={{ mt: 3 }}>
				<Grid item>
					<Typography variant="h3">期權資金流歷史</Typography>
				</Grid>
			</Grid>

			<MoneyFlowHistoryForm
				setLabel={setLabel}
				setCallIn={setCallIn}
				setCallTime={setCallTime}
				setPutIn={setPutIn}
				setPutTime={setPutTime}
			/>
			<div
				style={{
					margin: "auto",
					marginTop: 100,
					position: "relative",
					width: window.innerWidth < 576 ? "400px" : "70vw",
				}}
			>
				<Line data={data} options={options} />
			</div>
		</>
	);
};

export default MoneyFlowHistoryPage;
