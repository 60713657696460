import { Button, Grid, IconButton, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";
import {
	DataGrid,
	GridCellParams,
	GridColDef,
	GridValueSetterParams,
} from "@mui/x-data-grid";
import { arrayMoveImmutable } from "array-move";
import _ from "lodash";
import { toast } from "react-toastify";
import { OptionTableType } from "../../schemas/OptionTable";

interface IOptionTableForm {
	price: string;
	setPrice: React.Dispatch<React.SetStateAction<string>>;
	range: string;
	setRange: React.Dispatch<React.SetStateAction<string>>;
	setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	data: OptionTableType[];
	setData: React.Dispatch<React.SetStateAction<OptionTableType[]>>;
}

const OptionTableForm = ({
	price,
	setPrice,
	range,
	setRange,
	setModalOpen,
	data,
	setData,
}: IOptionTableForm) => {
	const moveOptionUp = (option: OptionTableType) => {
		const index = _.indexOf(data, option);
		if (index > 0) {
			setData(arrayMoveImmutable(data, index, index - 1));
		}
	};

	const moveOptionDown = (option: OptionTableType) => {
		const index = _.indexOf(data, option);
		setData(arrayMoveImmutable(data, index, index + 1));
	};

	const removeOption = (option: OptionTableType) => {
		let newData = [...data];
		setData(_.remove(newData, (o) => !_.isEqual(o, option)));
	};

	const updateOption = (option: OptionTableType) => {
		const index = _.findIndex(data, {
			side: option.side,
			strike: option.strike,
		});
		let newData = [...data];
		newData[index] = option;
		setData(newData);
	};

	const columns: GridColDef[] = [
		{ field: "side", headerName: "Call/Put", flex: 1, sortable: false },
		{
			field: "strike",
			headerName: "行使價",
			flex: 1,
			editable: true,
			valueSetter: (params: GridValueSetterParams) => {
				const strike = Number(params.value);
				params.row.strike = strike;
				updateOption(params.row);
				return { ...params.row };
			},
			sortable: false,
		},
		{
			field: "premium",
			headerName: "期權金",
			flex: 1,
			editable: true,
			valueParser: (
				value,
				params: GridCellParams<any, OptionTableType> | undefined
			) => {
				if (value === "") return;
				if (value === "-") return "-";
				const premium = Number(value);
				params!.row.premium = premium;
				updateOption(params!.row);
				return premium;
			},
			sortable: false,
		},
		{
			field: "quantity",
			headerName: "數量",
			flex: 1,
			editable: true,
			valueParser: (
				value,
				params: GridCellParams<any, OptionTableType> | undefined
			) => {
				if (value === "") return 1;
				if (value === "-") return "-";
				const quantity = Number(value);
				params!.row.quantity = quantity;
				updateOption(params!.row);
				return quantity;
			},
			sortable: false,
		},
		{
			field: "up",
			headerName: "向上",
			flex: 1,
			renderCell: (params) => (
				<IconButton
					color="inherit"
					edge="start"
					onClick={() => moveOptionUp(params.row)}
				>
					<ArrowUpwardIcon />
				</IconButton>
			),
			sortable: false,
		},
		{
			field: "down",
			headerName: "向下",
			flex: 1,
			renderCell: (params) => (
				<IconButton
					color="inherit"
					edge="start"
					onClick={() => moveOptionDown(params.row)}
				>
					<ArrowDownwardIcon />
				</IconButton>
			),
			sortable: false,
		},
		{
			field: "delete",
			headerName: "刪除",
			flex: 1,
			renderCell: (params) => (
				<IconButton
					color="inherit"
					edge="start"
					onClick={() => removeOption(params.row)}
				>
					<DeleteIcon />
				</IconButton>
			),
			sortable: false,
		},
	];

	return (
		<Grid container justifyContent="center" spacing={5} sx={{ mt: 3 }}>
			<Grid item xs={12} sm={1}></Grid>
			<Grid item xs={12} sm={2}>
				<Grid container spacing={5}>
					<Grid item xs={12}>
						<TextField
							label="現價"
							variant="outlined"
							type="number"
							value={price}
							onChange={(e) => setPrice(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							label="價格表間距"
							variant="outlined"
							type="number"
							value={range}
							onChange={(e) => setRange(e.target.value)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Button
							onClick={() => {
								if (price === "" || range === "") {
									toast.error("請輸入現價/價格表間距");
									return;
								}
								setModalOpen(true);
							}}
							startIcon={<AddIcon />}
							variant="contained"
							sx={{ height: 40 }}
						>
							添加 Option
						</Button>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} sm={9} sx={{ height: { xs: 400 } }}>
				{data.length === 0 ? (
					<></>
				) : (
					<DataGrid
						rows={data}
						columns={columns}
						disableColumnMenu
						hideFooter={true}
						getRowId={(row: OptionTableType) =>
							row.side + row.strike
						}
					/>
				)}
			</Grid>
		</Grid>
	);
};

export default OptionTableForm;
