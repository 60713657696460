import { joiResolver } from "@hookform/resolvers/joi";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import {
	Box,
	Button,
	Divider,
	InputAdornment,
	TextField,
	Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import ILoginData, { LoginFormSchema } from "../../schemas/Login";

interface ILoginForm {
	onFormSubmit: (data: ILoginData) => void;
}

const LoginForm = ({ onFormSubmit }: ILoginForm) => {
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<ILoginData>({
		defaultValues: { id: "", phone: "" },
		resolver: joiResolver(LoginFormSchema),
	});

	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			minHeight="90vh"
		>
			<Box
				sx={{
					backgroundColor: "#f0f0f0",
					border: "1px solid #f0f0f0",
					borderRadius: 5,
					flexDirection: "column",
					padding: 5,
					width: { xs: "300px", sm: "500px" },
				}}
				textAlign="center"
			>
				<Typography align="center" sx={{ pb: 2 }} variant="h4">
					登入
				</Typography>
				<Divider />
				<form onSubmit={handleSubmit((data) => onFormSubmit(data))}>
					<Controller
						name="id"
						control={control}
						render={({ field }) => (
							<Box sx={{ paddingTop: "30px" }}>
								<TextField
									label="Telegram ID"
									placeholder="請在QuantRaiser Bot 輸入 /ID 然後在以下填寫"
									sx={{ borderRadius: 1, width: "95%" }}
									InputLabelProps={{
										style: {
											padding: "2px",
											fontSize: 18,
											color: "grey",
										},
									}}
									InputProps={{
										style: { fontSize: 18, color: "black" },
										startAdornment: (
											<InputAdornment position="start">
												<AccountCircle />
											</InputAdornment>
										),
									}}
									{...field}
								/>
							</Box>
						)}
					/>
					<ErrorText>{errors.id?.message}</ErrorText>
					<Controller
						name="phone"
						control={control}
						render={({ field }) => (
							<Box sx={{ paddingTop: "30px" }}>
								<TextField
									label="電話號碼"
									placeholder="e.g. 12345678"
									sx={{ borderRadius: 1, width: "95%" }}
									InputLabelProps={{
										style: {
											padding: "2px",
											fontSize: 18,
											color: "grey",
										},
									}}
									InputProps={{
										style: { fontSize: 18, color: "black" },
										startAdornment: (
											<InputAdornment position="start">
												<LocalPhoneIcon />
											</InputAdornment>
										),
									}}
									type="password"
									{...field}
								/>
							</Box>
						)}
					/>
					<ErrorText>{errors.phone?.message}</ErrorText>
					<Button
						size="large"
						sx={{
							borderRadius: 3,
							marginTop: "30px",
							backgroundColor: "Orange",
							"&:hover": { backgroundColor: "#bd7f2f" },
						}}
						type="submit"
						variant="contained"
					>
						Sign in
					</Button>
				</form>
			</Box>
		</Box>
	);
};

const ErrorText = styled.p`
	color: red;
	font-size: 20px;
	margin: 10px 0px 0px 20px;
	text-align: start;
`;

export default LoginForm;
