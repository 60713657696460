import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { getConstituent } from "../utils/axios/constituents";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { isNil } from "lodash";

const cols: GridColDef[] = [
	{
		field: "symbol",
		align: "center",
		headerAlign: "center",
		headerName: "股票編號",
		flex: 1,
		minWidth: 100,
	},
	{
		field: "name",
		align: "center",
		headerAlign: "center",
		headerName: "名稱",
		flex: 1,
		minWidth: 150,
	},
	{
		field: "price",
		align: "center",
		headerAlign: "center",
		headerName: "股價",
		flex: 1,
		minWidth: 100,
	},
	{
		field: "change",
		align: "center",
		headerAlign: "center",
		headerName: "升跌",
		flex: 1,
		minWidth: 100,
	},
	{
		field: "change_percent",
		align: "center",
		headerAlign: "center",
		headerName: "升跌(%)",
		flex: 1,
		minWidth: 100,
	},
	{
		field: "weight",
		align: "center",
		headerAlign: "center",
		headerName: "佔指數比重",
		flex: 1,
		minWidth: 100,
	},
	{
		field: "per_stock",
		align: "center",
		headerAlign: "center",
		headerName: "每股佔點數",
		valueFormatter: (params) => `${params.value.toFixed(2)}`,
		flex: 1,
		minWidth: 100,
	},
	{
		field: "affect",
		align: "center",
		headerAlign: "center",
		headerName: "影響點數",
		valueFormatter: (params) => `${params.value.toFixed(2)}`,
		flex: 1,
		minWidth: 100,
	},
];

const ConstituentsPage = () => {
	const [indexNum, setIndexNum] = useState(0);

	const index = useMemo(() => {
		switch (indexNum) {
			case 0: {
				return "ixic";
			}
			default: {
				return "ixic";
			}
		}
	}, [indexNum]);

	const { data, isLoading, isFetching } = useQuery({
		queryKey: [index, "constituents"],
		queryFn: ({ queryKey }) => getConstituent(null, queryKey[0]),
		refetchOnWindowFocus: false,
	});

	return (
		<>
			<Grid container justifyContent={"center"} sx={{ mt: 3 }}>
				<Grid item>
					<Typography variant="h3">指數成份股</Typography>
				</Grid>
			</Grid>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs
					value={indexNum}
					onChange={(e, newValue) => setIndexNum(newValue)}
				>
					<Tab label="納斯達克指數 (^IXIC)" />
					{/* Add more tabs here */}
				</Tabs>
			</Box>
			{!isNil(data) && (
				<Typography variant="h4">
					指數現價: {data.index.close.toFixed(2)}{" "}
					{(data.index.close - data.index.ytd_close).toFixed(2)} (
					{(
						((data.index.close - data.index.ytd_close) /
							data.index.ytd_close) *
						100
					).toFixed(2)}
					%)
				</Typography>
			)}
			<DataGrid
				autoHeight
				rows={data?.constituents ?? []}
				columns={cols}
				loading={isLoading || isFetching}
				getRowId={(row) => row.symbol}
				initialState={{
					pagination: { pageSize: 50 },
				}}
			/>
		</>
	);
};

export default ConstituentsPage;
