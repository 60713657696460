import { Divider, Grid, Typography } from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { MoneyFlowListForm } from "../../components/MoneyFlowList";
import MoneyFlowListTable from "../../components/MoneyFlowList/moneyFlowListTable";
import { MoneyFlowReport } from "../../schemas/MoneyFlow";
import { MoneyFlowListReportData } from "../../schemas/MoneyFlowList";
import { getHkPercentYtd } from "../../utils/axios/moneyFlow";

const INDEX = ["hsi", "hhi"];

const MoneyFlowListPage = () => {
	const [expiryDate, setExpiryDate] = useState<string>("");
	const [expiryDates, setExpiryDates] = useState<string[]>([]);
	const [amount, setAmount] = useState<string>("20");
	const [fileDate, setFileDate] = useState<string>("");
	const [data, setData] = useState<MoneyFlowReport>();
	const [indexData, setIndexData] = useState<MoneyFlowListReportData[]>([]);
	const [stockCallData, setStockCallData] = useState<
		MoneyFlowListReportData[]
	>([]);
	const [stockPutData, setStockPutData] = useState<MoneyFlowListReportData[]>(
		[]
	);
	const [topBulls, setTopBulls] = useState<string[]>([]);
	const [topBears, setTopBears] = useState<string[]>([]);

	const getYtdData = useCallback(async () => {
		const jwt = localStorage.getItem("token");
		const ytdReportData = await getHkPercentYtd(jwt);
		setData(ytdReportData);
		setFileDate(ytdReportData.file_date);
		setTopBears(ytdReportData.top_bears);
		setTopBulls(ytdReportData.top_bulls);
		generateExpiryDates(ytdReportData);
	}, []);

	const getIndexData = useCallback(async () => {
		if (_.isEmpty(data)) return;
		const ytdDateReport = data![expiryDate];
		let iData: MoneyFlowListReportData[] = [];
		INDEX.forEach((index: string) => {
			const ytdSymbolReport: MoneyFlowListReportData =
				ytdDateReport[index as keyof typeof ytdDateReport];
			ytdSymbolReport["symbol"] = index;
			iData.push(ytdSymbolReport);
		});
		setIndexData(iData);
	}, [data, expiryDate]);

	const getStockData = useCallback(async () => {
		if (_.isEmpty(data)) return;
		const ytdDateReport = data![expiryDate];
		let scData: MoneyFlowListReportData[] = [];
		let spData: MoneyFlowListReportData[] = [];
		let takeAmount = topBulls.length;
		if (amount === "20") takeAmount = 10;

		_.take(topBulls, takeAmount).forEach((stock: string) => {
			const ytdSymbolReport: MoneyFlowListReportData =
				ytdDateReport[stock as keyof typeof ytdDateReport];
			if (
				ytdSymbolReport.call_in === 0 &&
				ytdSymbolReport.call_time === 0 &&
				ytdSymbolReport.put_in === 0 &&
				ytdSymbolReport.put_time === 0
			)
				return;
			ytdSymbolReport["symbol"] = stock;
			scData.push(ytdSymbolReport);
		});
		_.take(topBears, takeAmount).forEach((stock: string) => {
			const ytdSymbolReport: MoneyFlowListReportData =
				ytdDateReport[stock as keyof typeof ytdDateReport];
			if (
				ytdSymbolReport.call_in === 0 &&
				ytdSymbolReport.call_time === 0 &&
				ytdSymbolReport.put_in === 0 &&
				ytdSymbolReport.put_time === 0
			)
				return;

			ytdSymbolReport["symbol"] = stock;
			spData.push(ytdSymbolReport);
		});
		setStockCallData(
			_.sortBy(scData, [
				(symbol) => symbol.call_in / symbol.total,
			]).reverse()
		);
		setStockPutData(
			_.sortBy(spData, [
				(symbol) => symbol.put_in / symbol.total,
			]).reverse()
		);
	}, [amount, data, expiryDate, topBears, topBulls]);

	const generateExpiryDates = (report: MoneyFlowReport) => {
		let expiryDates: string[] = [];
		Object.keys(report).forEach((key) => {
			if (key.charAt(4) === "-") {
				expiryDates.push(key);
			}
		});
		setExpiryDate(expiryDates[0]);
		setExpiryDates(expiryDates);
	};

	useEffect(() => {
		getYtdData();
	}, [getYtdData]);

	useEffect(() => {
		getIndexData();
		getStockData();
	}, [getIndexData, getStockData]);

	return (
		<>
			<Grid container justifyContent={"center"} sx={{ my: 3 }}>
				<Grid item>
					<Typography variant="h3">期權市場資金流一覽</Typography>
				</Grid>
			</Grid>

			{!_.isEmpty(indexData) && (
				<MoneyFlowListTable
					data={indexData}
					fileDate={fileDate}
					side="call"
				/>
			)}

			<MoneyFlowListForm
				expiryDate={expiryDate}
				setExpiryDate={setExpiryDate}
				expiryDates={expiryDates}
				amount={amount}
				setAmount={setAmount}
			/>

			{!_.isEmpty(stockCallData) && (
				<MoneyFlowListTable
					data={stockCallData}
					fileDate={fileDate}
					side="call"
				/>
			)}

			<Divider
				sx={{
					backgroundColor: "black",
					borderBottomWidth: 5,
					marginY: 5,
				}}
			/>

			{!_.isEmpty(stockPutData) && (
				<MoneyFlowListTable
					data={stockPutData}
					fileDate={fileDate}
					side="put"
				/>
			)}
		</>
	);
};

export default MoneyFlowListPage;
