import { joiResolver } from "@hookform/resolvers/joi";
import {
	Autocomplete,
	Box,
	Button,
	Grid,
	Modal,
	TextField,
	Typography,
} from "@mui/material";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Option, OptionTableType } from "../../schemas/OptionTable";

interface IOptonTableModal {
	modalOpen: boolean;
	setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	data: OptionTableType[];
	setData: React.Dispatch<React.SetStateAction<OptionTableType[]>>;
}

const OptionTableModal = ({
	modalOpen,
	setModalOpen,
	data,
	setData,
}: IOptonTableModal) => {
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<OptionTableType>({
		defaultValues: {
			side: "Call",
			strike: 0,
			premium: 0,
			quantity: 1,
		},
		resolver: joiResolver(Option),
	});

	const onSubmit = (option: OptionTableType) => {
		if (_.find(data, { side: option.side, strike: option.strike })) {
			toast.error("這個行使價已添交");
			return;
		}

		setData([...data, option]);
		setModalOpen(false);
	};

	return (
		<Modal open={modalOpen} onClose={() => setModalOpen(false)}>
			<Box
				sx={{
					position: "absolute" as "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 400,
					height: 600,
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4,
				}}
			>
				<Typography variant="h4">添加 Option</Typography>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid
						container
						justifyContent={"center"}
						spacing={3}
						sx={{ mt: 3 }}
					>
						<Grid item xs={12}>
							<Controller
								name="side"
								control={control}
								render={({ field: { onChange, ...field } }) => (
									<Autocomplete
										disableClearable
										onChange={(_, data) =>
											onChange(data ? data : "")
										}
										options={["Call", "Put"]}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Call/Put"
											/>
										)}
										fullWidth
										{...field}
									/>
								)}
							/>
							<ErrorText>{errors.side?.message}</ErrorText>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name="strike"
								control={control}
								render={({ field }) => (
									<TextField
										label="行使價"
										variant="outlined"
										type="number"
										fullWidth
										{...field}
									/>
								)}
							/>
							<ErrorText>{errors.strike?.message}</ErrorText>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name="premium"
								control={control}
								render={({ field }) => (
									<TextField
										label="期權金"
										variant="outlined"
										type="number"
										fullWidth
										{...field}
									/>
								)}
							/>
							<ErrorText>{errors.premium?.message}</ErrorText>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name="quantity"
								control={control}
								render={({ field }) => (
									<TextField
										label="數量"
										variant="outlined"
										type="number"
										fullWidth
										{...field}
									/>
								)}
							/>
							<ErrorText>{errors.quantity?.message}</ErrorText>
						</Grid>
						<Grid item>
							<Button
								type="submit"
								variant="contained"
								sx={{ height: 50 }}
							>
								添加
							</Button>
						</Grid>
					</Grid>
				</form>
			</Box>
		</Modal>
	);
};

const ErrorText = styled.p`
	color: red;
	font-size: 20px;
	margin: 10px 0px 0px 20px;
	text-align: start;
`;

export default OptionTableModal;
