import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import {
	getContractMonths,
	getInPriceInverse,
	getInPrices,
} from "../../utils/axios/quotes";
import { XYData } from "../../schemas/Quotes/quotes";
import {
	Autocomplete,
	Button,
	Grid,
	TextField,
	Typography,
} from "@mui/material";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	TooltipItem,
	Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { XYInverseData } from "../../schemas/Quotes/quotes";
import { CustomJwtPayload } from "../../components/Header/header";
import jwtDecode from "jwt-decode";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const callOptions = {
	responsive: true,
	interaction: {
		intersect: false,
		mode: "index" as const,
	},
	plugins: {
		legend: {
			position: "top" as const,
		},
		title: {
			display: true,
			text: "Call Side",
		},
		tooltip: {
			callbacks: {
				title: (tooltipItem: TooltipItem<"line">[]) => {
					let label =
						parseFloat(tooltipItem[0].label!) >= 0
							? "入價"
							: "出價";
					label += tooltipItem[0].label;
					return label;
				},
				label: (tooltipItem: TooltipItem<"line">) => {
					let label = "報價:";
					label += tooltipItem.formattedValue;
					return label;
				},
			},
		},
	},
};

const putOptions = {
	responsive: true,
	interaction: {
		intersect: false,
		mode: "index" as const,
	},
	plugins: {
		legend: {
			position: "top" as const,
		},
		title: {
			display: true,
			text: "Put Side",
		},
		tooltip: {
			callbacks: {
				title: (tooltipItem: TooltipItem<"line">[]) => {
					let label =
						parseFloat(tooltipItem[0].label!) >= 0
							? "入價"
							: "出價";
					label += tooltipItem[0].label;
					return label;
				},
				label: (tooltipItem: TooltipItem<"line">) => {
					let label = "報價:";
					label += tooltipItem.formattedValue;
					return label;
				},
			},
		},
	},
};

const QuotesPage = () => {
	const [inPrice, setInPrice] = useState("");
	const [submit, setSubmit] = useState(false);
	const [callInPrices, setCallInPrices] = useState<XYData[]>([]);
	const [putInPrices, setPutInPrices] = useState<XYData[]>([]);
	const [callInPriceInverse, setCallInPriceInverse] = useState<
		XYInverseData[]
	>([]);
	const [putInPriceInverse, setPutInPriceInverse] = useState<XYInverseData[]>(
		[]
	);
	const [contractMonth, setContractMonth] = useState("");
	const [contractMonths, setContractMonths] = useState<string[]>([]);

	const jwt = localStorage.getItem("token");
	let payload: CustomJwtPayload;
	if (!jwt) payload = { telegram_id: 0, phone: "" };
	else payload = jwtDecode<CustomJwtPayload>(jwt);

	const callData = {
		labels: callInPrices.map((inPrice) => inPrice.x),
		datasets: [
			{
				label: "Call",
				data: callInPrices.map((inPrice) => inPrice.y),
				// data: _.filter(inPrices, { right: "C" }).map(
				// 	(quote) => (quote.bid + quote.ask) / 2
				// ),
				borderColor: "rgb(53, 162, 235)",
				backgroundColor: "rgba(53, 162, 235, 0.5)",
				tension: 0.4,
			},
		],
	};

	const putData = {
		labels: putInPrices.map((inPrice) => inPrice.x),

		datasets: [
			{
				label: "Put",
				data: putInPrices.map((inPrice) => inPrice.y),
				borderColor: "rgb(255, 99, 132)",
				backgroundColor: "rgba(255, 99, 132, 0.5)",
				tension: 0.4,
			},
		],
	};

	const callInverseData = {
		labels: callInPriceInverse.map((e) => e.strategy_price),
		datasets: [
			{
				label: "Call",
				data: callInPriceInverse.map((e) => e.in_price),
				borderColor: "rgb(53, 162, 235)",
				backgroundColor: "rgba(53, 162, 235, 0.5)",
				tension: 0.4,
			},
		],
	};

	const putInverseData = {
		labels: putInPriceInverse.map((e) => e.strategy_price),
		datasets: [
			{
				label: "Put",
				data: putInPriceInverse.map((e) => e.in_price),
				borderColor: "rgb(255, 99, 132)",
				backgroundColor: "rgba(255, 99, 132, 0.5)",
				tension: 0.4,
			},
		],
	};

	const getInPricesData = async () => {
		const jwt = localStorage.getItem("token");

		const { C, P } = await getInPrices(
			"hhi.hk",
			parseFloat(inPrice),
			contractMonth,
			jwt
		);
		// console.log({ C, P });
		setCallInPrices(_.sortBy(C, ["x"]));
		setPutInPrices(_.sortBy(P, ["x"]));

		// setTimeout(getInPricesData, 3000);
	};

	const getInPriceInverseData = async () => {
		const jwt = localStorage.getItem("token");

		const { C, P } = await getInPriceInverse("hhi.hk", contractMonth, jwt);
		setCallInPriceInverse(_.sortBy(C, ["strategy_price"]));
		setPutInPriceInverse(_.sortBy(P, ["strategy_price"]));
	};

	const onSubmitHandler = async () => {
		setSubmit(true);

		await getInPricesData();
		await getInPriceInverseData();
	};

	const getContractMonthsData = useCallback(async () => {
		const jwt = localStorage.getItem("token");

		const contractMonths = await getContractMonths(jwt);
		setContractMonths(contractMonths);
	}, []);

	useEffect(() => {
		getContractMonthsData();
	}, [getContractMonthsData]);

	return (
		<>
			<Grid container justifyContent={"center"} sx={{ mt: 3 }}>
				<Grid item>
					<Typography variant="h3">期權報價表</Typography>
				</Grid>
			</Grid>

			<Grid container justifyContent="center" sx={{ mt: 3 }}>
				<Grid item>
					<TextField
						label="入價"
						variant="outlined"
						type="number"
						value={inPrice}
						onChange={(e) => setInPrice(e.target.value)}
						disabled={submit}
					/>
				</Grid>
				<Grid item>
					<Autocomplete
						disabled={submit}
						disableClearable={true}
						value={contractMonth}
						onChange={(_, newValue) => setContractMonth(newValue)}
						options={contractMonths}
						renderInput={(params) => (
							<TextField {...params} label="期結日" />
						)}
						sx={{ width: 150, ml: 3 }}
					/>
				</Grid>
				{!submit && (
					<Grid item>
						<Button
							sx={{ height: 55, ml: 3 }}
							type="submit"
							variant="contained"
							onClick={onSubmitHandler}
						>
							提交
						</Button>
					</Grid>
				)}

				{submit && (
					<Grid item>
						<Button
							sx={{ height: 55, ml: 3 }}
							onClick={() => window.location.reload()}
							variant="contained"
						>
							重置
						</Button>
					</Grid>
				)}
			</Grid>

			<Grid container sx={{ mt: 3 }}>
				<Grid item xs={12}>
					<div
						style={{
							margin: "auto",
							position: "relative",
							width: window.innerWidth < 576 ? "400px" : "70vw",
						}}
					>
						{!_.isEmpty(callInPrices) && (
							<Line data={callData} options={callOptions} />
						)}
					</div>
				</Grid>
				<Grid item xs={12}>
					<div
						style={{
							margin: "auto",
							position: "relative",
							width: window.innerWidth < 576 ? "400px" : "70vw",
						}}
					>
						{!_.isEmpty(putInPrices) && (
							<Line data={putData} options={putOptions} />
						)}
					</div>
				</Grid>
			</Grid>

			{_.includes([352264299, 1125124970], payload.telegram_id) && (
				<Grid container sx={{ mt: 3 }}>
					<Grid item xs={12}>
						<div
							style={{
								margin: "auto",
								position: "relative",
								width:
									window.innerWidth < 576 ? "400px" : "70vw",
							}}
						>
							{!_.isEmpty(callInPriceInverse) && (
								<Line
									data={callInverseData}
									options={{
										responsive: true,
										interaction: {
											intersect: false,
											mode: "index" as const,
										},
									}}
								/>
							)}
						</div>
					</Grid>
					<Grid item xs={12}>
						<div
							style={{
								margin: "auto",
								position: "relative",
								width:
									window.innerWidth < 576 ? "400px" : "70vw",
							}}
						>
							{!_.isEmpty(putInPriceInverse) && (
								<Line
									data={putInverseData}
									options={{
										responsive: true,
										interaction: {
											intersect: false,
											mode: "index" as const,
										},
									}}
								/>
							)}
						</div>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default QuotesPage;
