import axios from "axios";
import { toast } from "react-toastify";
import { ConstituentData } from "../../schemas/Constituents";

const ConstituentsAxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_CONSTITUENT_BASE_URL,
	headers: { "Content-Type": "application/json" },
});

export const getConstituent = async (
	token: string | null,
	index: string
): Promise<ConstituentData> => {
	try {
		let res;
		if (token) {
			res = await ConstituentsAxiosInstance.get(`/${index}`, {
				// headers: {
				// 	Authorization: `Bearer ${token}`,
				// },
			});
		} else {
			res = await ConstituentsAxiosInstance.get(`/${index}`);
		}
		return res.data.data;
	} catch (err) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + err);
		return {} as ConstituentData;
	}
};
