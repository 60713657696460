import { Box, Typography } from "@mui/material";
import {
	DataGrid,
	GridCellParams,
	GridColDef,
	GridValidRowModel,
} from "@mui/x-data-grid";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface IDividendTable {
	date: string;
	data: {};
	strikeAmount: string;
	nowPrice: number;
}

const columns: GridColDef[] = [
	{
		field: "putIv",
		align: "center",
		headerAlign: "center",
		headerName: "IV",
		flex: 1,
		sortable: false,
		valueFormatter: (params) => `${params.value.toFixed(2)}%`,
	},
	{
		field: "putAction",
		align: "center",
		headerAlign: "center",
		headerName: "做法",
		flex: 2,
		sortable: false,
	},
	{
		field: "putPremium",
		align: "center",
		headerAlign: "center",
		headerName: "期權金	",
		flex: 1,
		sortable: false,
		valueFormatter: (params) => params.value.toFixed(2),
	},
	{
		field: "putDividend",
		align: "center",
		headerAlign: "center",
		headerName: "年息率",
		valueFormatter: (params) => `${params.value.toFixed(2)}%`,
		flex: 1,
		sortable: false,
	},
	{
		field: "putStatus",
		align: "center",
		headerAlign: "center",
		headerName: "狀態",
		flex: 1,
		sortable: false,
	},
	{
		field: "strike",
		align: "center",
		headerAlign: "center",
		headerName: "行使價",
		flex: 1,
		type: "number",
	},
	{
		field: "callStatus",
		align: "center",
		headerAlign: "center",
		headerName: "狀態",
		flex: 1,
		sortable: false,
	},
	{
		field: "callDividend",
		align: "center",
		headerAlign: "center",
		headerName: "年息率",
		valueFormatter: (params) => `${params.value.toFixed(2)}%`,
		flex: 1,
		sortable: false,
	},
	{
		field: "callPremium",
		align: "center",
		headerAlign: "center",
		headerName: "期權金	",
		flex: 1,
		sortable: false,
		valueFormatter: (params) => params.value.toFixed(2),
	},
	{
		field: "callAction",
		align: "center",
		headerAlign: "center",
		headerName: "做法",
		flex: 3,
		sortable: false,
	},
	{
		field: "callIv",
		align: "center",
		headerAlign: "center",
		headerName: "IV",
		flex: 1,
		sortable: false,
		valueFormatter: (params) => `${params.value.toFixed(2)}%`,
	},
];

const statusToChinese = (status: string) => {
	if (status === "ITM") return "價內";
	else if (status === "OTM") return "價外";
	else return "等價";
};

const DividendTable = ({
	date,
	data,
	strikeAmount,
	nowPrice,
}: IDividendTable) => {
	const [monthData, setMonthData] = useState<GridValidRowModel[]>([]);

	const { id } = useParams();

	const setContentData = useCallback(() => {
		let strikes = Object.keys(data);
		if (strikeAmount === "20") {
			const closest = strikes.reduce((prev, curr) => {
				return Math.abs(parseFloat(curr) - nowPrice) <
					Math.abs(parseFloat(prev) - nowPrice)
					? curr
					: prev;
			});
			const closestIndex = strikes.indexOf(closest);
			const minIndex = Math.max(0, closestIndex - 10);
			const maxIndex = Math.min(strikes.length - 1, closestIndex + 10);
			strikes = strikes.slice(minIndex, maxIndex + 1);
		}

		let result: GridValidRowModel[] = [];
		strikes.forEach((strike) => {
			const strikeData = data[strike as keyof typeof data];
			const callData = strikeData["covered_call"];
			const putData = strikeData["naked_short_put"];
			result.push({
				id: `${strike}`,
				putIv: putData["iv"],
				putAction: `Short Put ${id} @ ${strike}`,
				putPremium: putData["price"],
				putDividend: putData["dividend"],
				putStatus: statusToChinese(putData["status"]),
				strike: strike,
				callStatus: statusToChinese(callData["status"]),
				callDividend: callData["dividend"],
				callPremium: callData["price"],
				callAction: `正股購入位置@${nowPrice} + Short Call ${id} @ ${strike}`,
				callIv: callData["iv"],
			});
		});
		setMonthData(result);
	}, [data, id, nowPrice, strikeAmount]);

	useEffect(() => {
		if (!_.isEmpty(data)) setContentData();
	}, [data, setContentData]);

	return (
		<>
			{_.isEmpty(monthData) ? null : (
				<Box sx={{ mt: 5 }}>
					<Typography variant="h4" sx={{ ml: 5 }}>
						到期日: {date}
					</Typography>
					<Box
						sx={{
							display: "flex",
							flexDirection: { xs: "column", sm: "row" },
						}}
					>
						<Box
							sx={{
								width: "100%",
								"& .put": {
									color: "red",
								},
								"& .call": {
									color: "blue",
								},
							}}
						>
							<DataGrid
								autoHeight
								rows={monthData}
								columns={columns}
								disableColumnMenu
								getCellClassName={(params: GridCellParams) => {
									if (params.field.includes("call")) {
										return "call";
									} else if (params.field.includes("put")) {
										return "put";
									}
									return "";
								}}
								hideFooter={true}
								initialState={{
									sorting: {
										sortModel: [
											{ field: "strike", sort: "desc" },
										],
									},
								}}
								sx={{
									".MuiDataGrid-cell": {
										textAlign: "center",
										whiteSpace: "normal !important",
									},
								}}
							/>
						</Box>
					</Box>
				</Box>
			)}
		</>
	);
};

export default DividendTable;
