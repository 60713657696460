import Joi from "joi";

export default interface ILoginData {
	id: string;
	phone: string;
}

export interface JWT {
	jwt: string;
}

export const LoginFormSchema = Joi.object({
	id: Joi.number().integer().required().messages({
		"number.base": "Telegram ID 只接受'0-9'的數字",
	}),
	phone: Joi.string().regex(/^\d+$/).required().messages({
		"string.empty": "請填寫電話號碼",
		"string.pattern.base": "電話號碼只接受'0-9'的數字",
	}),
});
