import { Grid, Typography } from "@mui/material";
import ExcelMonthForm from "../components/Excel/excelMonthForm";
import { useEffect, useMemo, useState } from "react";
import { ExcelWebMonthType } from "../schemas/ExcelWeb";
import { DataGrid, GridValueFormatterParams } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import jwtDecode, { JwtPayload } from "jwt-decode";

const ExcelMonthPage = () => {
	const [data, setData] = useState<ExcelWebMonthType[]>([]);

	const columns = useMemo(
		() => [
			{
				field: "code",
				headerName: "Code",
				flex: 1.5,
				minWidth: 170,
			},
			{
				field: "expected_vola",
				headerName: "預測波幅",
				flex: 1,
				valueFormatter: (params: GridValueFormatterParams<number>) =>
					`${(params.value * 100).toFixed(2)}%`,
			},
			{
				field: "prob",
				headerName: "機率",
				flex: 1,
				valueFormatter: (params: GridValueFormatterParams<number>) =>
					`${(params.value * 100).toFixed(2)}%`,
			},
			{
				field: "real_start_date",
				headerName: "開始日期",
				flex: 1,
				minWidth: 100,
			},
			{
				field: "real_end_date",
				headerName: "完結日期",
				flex: 1,
				minWidth: 100,
			},
			{
				field: "buy_price",
				headerName: "買價",
				flex: 1,
				valueFormatter: (params: GridValueFormatterParams<number>) =>
					`${params.value.toFixed(2)}`,
			},
			{
				field: "sell_price",
				headerName: "賣價",
				flex: 1,
				valueFormatter: (params: GridValueFormatterParams<number>) =>
					`${params.value.toFixed(2)}`,
			},
			{
				field: "max_high",
				headerName: "最高",
				flex: 1,
				valueFormatter: (params: GridValueFormatterParams<number>) =>
					`${params.value.toFixed(2)}`,
			},
			{
				field: "min_low",
				headerName: "最低",
				flex: 1,
				valueFormatter: (params: GridValueFormatterParams<number>) =>
					`${params.value.toFixed(2)}`,
			},
			{
				field: "actual_vola",
				headerName: "實際波幅",
				flex: 1,
				valueFormatter: (params: GridValueFormatterParams<number>) =>
					`${(params.value * 100).toFixed(2)}%`,
			},
			{
				field: "actual_vola_date",
				headerName: "實際波幅日期",
				flex: 1,
				minWidth: 100,
			},
		],
		[]
	);

	const navigate = useNavigate();

	useEffect(() => {
		const jwt = localStorage.getItem("token");
		if (!jwt) {
			navigate("/");
			return;
		}
		const payload = jwtDecode<JwtPayload & { telegram_id: number }>(jwt);
		if (![1125124970, 352264299].includes(payload.telegram_id)) {
			navigate("/");
			return;
		}
	}, [navigate]);

	return (
		<>
			<Grid container justifyContent={"center"} sx={{ mt: 3 }}>
				<Grid item>
					<Typography variant="h3">Data對數表(by month)</Typography>
				</Grid>
			</Grid>
			<Grid container sx={{ mt: 3 }}>
				<Grid item xs={12}>
					<ExcelMonthForm setData={(val) => setData(val)} />
				</Grid>
			</Grid>
			<Grid container sx={{ mt: 3 }}>
				<Grid item xs={12}>
					<DataGrid
						autoHeight
						rows={data}
						columns={columns}
						getRowId={(params) => params.code}
					/>
				</Grid>
			</Grid>
		</>
	);
};
export default ExcelMonthPage;
