import dayjs from "dayjs";

interface periodOption {
	label: string;
	value: string;
}

export const periodOptions: periodOption[] = [
	{
		label: "一星期",
		value: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
	},
	{
		label: "兩星期",
		value: dayjs().subtract(14, "day").format("YYYY-MM-DD"),
	},
	{
		label: "四星期",
		value: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
	},
];
