import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import {
	ExcelMonthFormSchema,
	ExcelWebMonthType,
	TExcelMonthForm,
} from "../../schemas/ExcelWeb";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { getExcelWebMonthData } from "../../utils/axios/excelWeb";

export interface IExcelMonthForm {
	setData: (data: ExcelWebMonthType[]) => void;
}

const ExcelMonthForm = ({ setData }: IExcelMonthForm) => {
	const [isLoading, setIsLoading] = useState(false);

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<TExcelMonthForm>({
		defaultValues: { symbol: "", monthOver: 1, volaType: "升波幅" },
		resolver: zodResolver(ExcelMonthFormSchema),
	});

	const onSubmitHandler = async (data: TExcelMonthForm) => {
		setIsLoading(true);
		const volaType =
			data.volaType === "升波幅"
				? "max_high"
				: data.volaType === "跌波幅"
				? "min_low"
				: "abs_max";
		const result = await getExcelWebMonthData(
			data.symbol,
			data.monthOver,
			volaType,
			null
		);
		setData(result);
		setIsLoading(false);
	};

	return (
		<form onSubmit={handleSubmit(onSubmitHandler)}>
			<Grid container justifyContent="center" spacing={2}>
				<Grid item>
					<Controller
						control={control}
						name="symbol"
						render={({ field }) => (
							<TextField label="Symbol" {...field} />
						)}
					/>
					{errors.symbol && (
						<Typography color="red">
							{errors.symbol.message}
						</Typography>
					)}
				</Grid>
				<Grid item>
					<Controller
						name="monthOver"
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<Autocomplete
								disableClearable
								options={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
								getOptionLabel={(option) => option.toString()}
								onChange={(_, data) =>
									onChange(data ? data : 0)
								}
								renderInput={(params) => (
									<TextField
										label="Month Over"
										{...field}
										{...params}
									/>
								)}
								sx={{ width: 130 }}
								{...field}
							/>
						)}
					/>
					{errors.monthOver && (
						<Typography color="red">
							{errors.monthOver.message}
						</Typography>
					)}
				</Grid>
				<Grid item>
					<Controller
						name="volaType"
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<Autocomplete
								disableClearable
								options={["升波幅", "跌波幅", "最大波幅"]}
								onChange={(_, data) =>
									onChange(data ? data : "")
								}
								renderInput={(params) => (
									<TextField
										label="波幅"
										{...field}
										{...params}
									/>
								)}
								sx={{ width: 130 }}
								{...field}
							/>
						)}
					/>
					{errors.volaType && (
						<Typography color="red">
							{errors.volaType.message}
						</Typography>
					)}
				</Grid>
				<Grid item>
					<LoadingButton
						disabled={isLoading}
						loading={isLoading}
						size="medium"
						type="submit"
						variant="contained"
						sx={{ height: 56 }}
					>
						Submit
					</LoadingButton>
				</Grid>
			</Grid>
		</form>
	);
};
export default ExcelMonthForm;
