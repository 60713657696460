import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	TooltipItem,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { MoneyFlowListReportData } from "../../schemas/MoneyFlowList";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const numberFormatter = Intl.NumberFormat("en", { notation: "compact" });

interface HeadCell {
	id: string;
	label: string;
}

const options = {
	indexAxis: "y" as const,
	interaction: {
		intersect: false,
		mode: "index" as const,
	},
	elements: {
		bar: {
			borderWidth: 2,
		},
	},
	responsive: true,
	maintainAspectRatio: false,
	scales: {
		x: {
			display: false,
			stacked: true,
			max: 1,
		},
		y: {
			display: false,
			stacked: true,
		},
	},
	plugins: {
		legend: {
			display: false,
		},
		title: {
			display: false,
		},
		tooltip: {
			callbacks: {
				label: (tooltipItem: TooltipItem<"bar">) => {
					return `${tooltipItem.dataset.label}: ${(
						Number(tooltipItem.formattedValue) * 100
					)
						.toFixed(2)
						.toString()}%`;
				},
			},
		},
	},
};

interface IMoneyFlowListTable {
	data: MoneyFlowListReportData[];
	fileDate: string;
	side: string;
}

const MoneyFlowListTable = ({ data, fileDate, side }: IMoneyFlowListTable) => {
	const headCells: HeadCell[] = [
		{ id: "amount", label: "商品" },
		{ id: "date", label: `交易日 ${fileDate}` },
		{ id: "total", label: "市場總值" },
		{ id: "call", label: "好倉" },
		{ id: "put", label: "淡倉" },
	];

	const symbolCallChartData = (symbolData: MoneyFlowListReportData) => {
		return {
			labels: [symbolData.symbol],
			datasets: [
				{
					label: "Call價內",
					data: [symbolData.call_in / symbolData.total],
					backgroundColor: "rgba(0, 115, 230, 1)",
					barPercentage: 0.5,
				},
				{
					label: "Call時間",
					data: [symbolData.call_time / symbolData.total],

					backgroundColor: "rgba(0, 115, 230, 0.2)",
					barPercentage: 0.5,
				},
				{
					label: "Put價內",
					data: [symbolData.put_in / symbolData.total],
					backgroundColor: "rgba(237, 125, 49, 1)",
					barPercentage: 0.5,
				},
				{
					label: "Put時間",
					data: [symbolData.put_time / symbolData.total],

					backgroundColor: "rgba(237, 125, 49, 0.2)",
					barPercentage: 0.5,
				},
			],
		};
	};

	const symbolPutChartData = (symbolData: MoneyFlowListReportData) => {
		return {
			labels: [symbolData.symbol],
			datasets: [
				{
					label: "Put價內",
					data: [symbolData.put_in / symbolData.total],
					backgroundColor: "rgba(237, 125, 49, 1)",
					barPercentage: 0.5,
				},
				{
					label: "Put時間",
					data: [symbolData.put_time / symbolData.total],

					backgroundColor: "rgba(237, 125, 49, 0.2)",
					barPercentage: 0.5,
				},
				{
					label: "Call時間",
					data: [symbolData.call_time / symbolData.total],

					backgroundColor: "rgba(0, 115, 230, 0.2)",
					barPercentage: 0.5,
				},
				{
					label: "Call價內",
					data: [symbolData.call_in / symbolData.total],
					backgroundColor: "rgba(0, 115, 230, 1)",
					barPercentage: 0.5,
				},
			],
		};
	};

	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						{headCells.map((headCell) => (
							<TableCell
								key={headCell.id}
								align="center"
								sx={{ fontSize: 20 }}
							>
								{headCell.label}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map((symbolData) => (
						<TableRow key={symbolData.symbol}>
							<TableCell sx={{ fontSize: 20, width: 200 }}>
								{symbolData.name} ({symbolData.symbol})
							</TableCell>
							<TableCell>
								<Bar
									data={
										side === "call"
											? symbolCallChartData(symbolData)
											: symbolPutChartData(symbolData)
									}
									options={options}
								/>
							</TableCell>
							<TableCell sx={{ fontSize: 20 }}>
								{numberFormatter.format(symbolData.total)} (
								{(
									symbolData.total_change_percent * 100
								).toFixed(2)}
								%)
							</TableCell>
							<TableCell sx={{ fontSize: 20 }}>
								{numberFormatter.format(symbolData.call)} (
								{(symbolData.call_change_percent * 100).toFixed(
									2
								)}
								%)
							</TableCell>
							<TableCell sx={{ fontSize: 20 }}>
								{numberFormatter.format(symbolData.put)} (
								{(symbolData.put_change_percent * 100).toFixed(
									2
								)}
								%)
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default MoneyFlowListTable;
