import axios from "axios";
import { toast } from "react-toastify";
import {
	Filter,
	FilterDetail,
	UserFilterRecord,
} from "../../schemas/TakeProfitTargetUs";

const FilterAxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_FILTER_BASE_URL,
	headers: { "Content-Type": "application/json" },
});

export const getFilterData = async (
	token: string | null,
	startDate: string,
	endDate: string,
	cost: number,
	listNumber: string,
	symbols: string,
	type: "us" | "hk"
): Promise<Filter[]> => {
	try {
		let res;
		if (token) {
			res = await FilterAxiosInstance.get(`/${type}`, {
				params: {
					start_date: startDate,
					end_date: endDate,
					cost: cost,
					list: listNumber,
					symbols: symbols,
				},
				// headers: {
				// 	Authorization: `Bearer ${token}`,
				// },
			});
		} else {
			res = await FilterAxiosInstance.get(`/${type}`, {
				params: {
					start_date: startDate,
					end_date: endDate,
					cost: cost,
					list: listNumber,
					symbols: symbols,
				},
			});
		}
		toast.success("成功");
		const data = res.data;
		return data.data;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return [];
	}
};

export const saveFilterRecord = async (
	token: string | null,
	telegramId: number,
	symbol: string,
	probability: number,
	startDate: string,
	endDate: string,
	cost: number
) => {
	try {
		if (token) {
			await FilterAxiosInstance.post(
				"/record",
				{
					telegram_id: telegramId,
					symbol: symbol,
					probability: probability,
					start_date: startDate,
					end_date: endDate,
					cost: cost,
				}
				// headers: {
				// 	Authorization: `Bearer ${token}`,
				// },
			);
		} else {
			await FilterAxiosInstance.post("/record", {
				telegram_id: telegramId,
				symbol: symbol,
				probability: probability,
				start_date: startDate,
				end_date: endDate,
				cost: cost,
			});
		}
		toast.success("成功儲存記錄,請到我的記錄頁面查看");
	} catch (err) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + err);
	}
};

export const deleteFilterRecord = async (token: string | null, id: string) => {
	try {
		if (token) {
			await FilterAxiosInstance.delete(
				`/record/${id}`
				// headers: {
				// 	Authorization: `Bearer ${token}`,
				// },
			);
		} else {
			await FilterAxiosInstance.delete(`/record/${id}`);
		}
		toast.success("成功");
	} catch (err) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + err);
	}
};

export const listUserFilterRecords = async (
	token: string | null,
	telegramId: number
): Promise<UserFilterRecord[]> => {
	try {
		let res;
		if (token) {
			res = await FilterAxiosInstance.get("/record", {
				params: {
					telegram_id: telegramId,
				},
				// headers: {
				// 	Authorization: `Bearer ${token}`,
				// },
			});
		} else {
			res = await FilterAxiosInstance.get("/record", {
				params: {
					telegram_id: telegramId,
				},
			});
		}
		const data = res.data;
		return data.data;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return [];
	}
};

export const listUserFilterDetailRecords = async (
	token: string | null,
	telegramId: number
): Promise<FilterDetail[]> => {
	try {
		let res;
		if (token) {
			res = await FilterAxiosInstance.get("/record/detail", {
				params: {
					telegram_id: telegramId,
				},
				// headers: {
				// 	Authorization: `Bearer ${token}`,
				// },
			});
		} else {
			res = await FilterAxiosInstance.get("/record/detail", {
				params: {
					telegram_id: telegramId,
				},
			});
		}
		const data = res.data;
		return data.data;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return [];
	}
};
