import { useNavigate } from "react-router-dom";
import LoginForm from "../../components/Login";
import ILoginData from "../../schemas/Login";
import { login } from "../../utils/axios/login";

const LoginPage = () => {
	const navigate = useNavigate();

	const onFormSubmit = async (data: ILoginData) => {
		(await login(data.id, data.phone)) && navigate("/home");
	};

	return <LoginForm onFormSubmit={onFormSubmit} />;
};

export default LoginPage;
