import { Box, Grid, Modal, Typography } from "@mui/material";
import MoneyFlowUsForm from "../components/MoneyFlowUs/moneyFlowUsForm";
import { useQuery } from "@tanstack/react-query";
import { listOptionData } from "../utils/axios/moneyFlowUs";
import { useMemo, useState } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import {
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend,
	BarElement,
	CategoryScale,
	LinearScale,
	Title,
} from "chart.js";
import { inRange, isNil, map, pick, pickBy } from "lodash";
import ChartDataLabels, { Context } from "chartjs-plugin-datalabels";

ChartJS.register(
	ArcElement,
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
	BarElement,
	Title
);

const MoneyFlowUsPage = () => {
	const [symbol, setSymbol] = useState("");
	const [contractMonth, setContractMonth] = useState("");
	const [barModalOpen, setBarModalOpen] = useState(false);

	const { data } = useQuery({
		queryKey: [symbol, contractMonth, "moneyflowUs"],
		queryFn: ({ queryKey }) => {
			if (symbol === "" || contractMonth === "") {
				return null;
			}
			return listOptionData(null, queryKey[0], queryKey[1]);
		},
		refetchInterval: 10000,
	});

	const sortedOptionValue = useMemo(() => {
		if (isNil(data)) {
			return {};
		}
		return pick(
			data.value,
			Object.keys(data.value).sort((a, b) =>
				a.localeCompare(b, undefined, { numeric: true })
			)
		);
	}, [data]);

	const filteredOptionValue = useMemo(() => {
		if (isNil(data)) {
			return {};
		}
		const nowPrice = data.now_price;
		const strikes = Object.keys(sortedOptionValue);
		let index = 0;
		while (parseFloat(strikes[index]) < nowPrice) {
			index++;
		}
		const startIndex = Math.max(0, index - 10);
		const endIndex = Math.min(strikes.length - 1, index + 10);
		const startStrike = strikes[startIndex];
		const endStrike = strikes[endIndex];
		return pickBy(sortedOptionValue, (value, key) =>
			inRange(
				parseFloat(key),
				parseFloat(startStrike),
				parseFloat(endStrike)
			)
		);
	}, [data, sortedOptionValue]);

	const percentData = {
		labels: ["Call價內", "Call時間", "Put價內", "Put時間"],
		datasets: [
			{
				label: "Realtime",
				data: isNil(data)
					? []
					: [
							data.percent.call_in,
							data.percent.call_time,
							data.percent.put_in,
							data.percent.put_time,
					  ],
				backgroundColor: [
					"rgba(0, 115, 230, 1)",
					"rgba(237, 125, 49, 1)",
					"rgba(0, 176, 80, 1)",
					"rgba(122, 0, 204, 1)",
				],
				borderColor: "rgba(255, 255, 255, 1)",
				borderWidth: 1,
				weight: 1,
			},
			{
				label: "Yesterday",
				data: [],
			},
		],
	};

	const percentOptions = {
		animation: {
			duration: 0,
		},
		plugins: {
			datalabels: {
				color: "#000000",
				font: { size: 15 },
				formatter: (value: number, context: Context) =>
					`${Math.round(
						(value /
							[
								data?.percent.call_in ?? 0,
								data?.percent.call_time ?? 0,
								data?.percent.put_in ?? 0,
								data?.percent.put_time ?? 0,
							].reduce((a, b) => a + b, 0)) *
							100
					)}%`,
			},
			legend: {
				position: "right" as const,
				align: "end" as const,
				labels: {
					boxWidth: 40,
					font: { size: 20 },
					padding: 20,
				},
			},
			title: {
				display: true,
				text: [
					`${symbol} 期權好淡變化`,
					`(合約月份: ${contractMonth})`,
				],
				font: { size: 30 },
			},
		},
	};

	const barData = {
		labels: Object.keys(filteredOptionValue).sort().reverse(),
		datasets: [
			{
				label: "Call價內",
				data: map(filteredOptionValue, (e) => e.call_in).reverse(),
				borderColor: "rgba(255, 255, 255, 1)",
				backgroundColor: "rgba(0, 115, 230, 1)",
				barPercentage: 0.5,
			},
			{
				label: "Call時間",
				data: map(filteredOptionValue, (e) => e.call_time).reverse(),
				borderColor: "rgba(255, 255, 255, 1)",
				backgroundColor: "rgba(237, 125, 49, 1)",
				barPercentage: 0.5,
			},
			{
				label: "Put價內",
				data: map(filteredOptionValue, (e) => e.put_in).reverse(),
				borderColor: "rgba(255, 255, 255, 1)",
				backgroundColor: "rgba(0, 176, 80, 1)",
				barPercentage: 0.5,
			},
			{
				label: "Put時間",
				data: map(filteredOptionValue, (e) => e.put_time).reverse(),
				borderColor: "rgba(255, 255, 255, 1)",
				backgroundColor: "rgba(122, 0, 204, 1)",
				barPercentage: 0.5,
			},
		],
	};

	const barOptions = {
		animation: {
			duration: 0,
		},
		indexAxis: "y" as const,
		interaction: {
			intersect: false,
			mode: "y" as const,
		},
		scales: {
			x: {
				stacked: true,
			},
			y: {
				stacked: true,
			},
		},
		plugins: {
			legend: {
				position: "right" as const,
				align: "end" as const,
				fullWidth: false,
				labels: {
					font: {
						size: 20,
					},
				},
				padding: 20,
			},
			maintainAspectRatio: false,
			title: {
				display: true,
				text: [`${symbol} 期權資金流`, `(合約月份: ${contractMonth})`],
				position: "top" as const,
				font: {
					size: 30,
				},
			},
		},
	};

	const barAllData = {
		labels: Object.keys(sortedOptionValue).reverse(),
		datasets: [
			{
				label: "Call價內",
				data: map(sortedOptionValue, (e) => e.call_in).reverse(),
				borderColor: "rgba(255, 255, 255, 1)",
				backgroundColor: "rgba(0, 115, 230, 1)",
				barPercentage: 0.5,
			},
			{
				label: "Call時間",
				data: map(sortedOptionValue, (e) => e.call_time).reverse(),
				borderColor: "rgba(255, 255, 255, 1)",
				backgroundColor: "rgba(237, 125, 49, 1)",
				barPercentage: 0.5,
			},
			{
				label: "Put價內",
				data: map(sortedOptionValue, (e) => e.put_in).reverse(),
				borderColor: "rgba(255, 255, 255, 1)",
				backgroundColor: "rgba(0, 176, 80, 1)",
				barPercentage: 0.5,
			},
			{
				label: "Put時間",
				data: map(sortedOptionValue, (e) => e.put_time).reverse(),
				borderColor: "rgba(255, 255, 255, 1)",
				backgroundColor: "rgba(122, 0, 204, 1)",
				barPercentage: 0.5,
			},
		],
	};

	const barAllOptions = {
		animation: {
			duration: 0,
		},
		indexAxis: "y" as const,
		interaction: {
			intersect: false,
			mode: "y" as const,
		},
		scales: {
			x: {
				stacked: true,
			},
			y: {
				stacked: true,
			},
		},
		plugins: {
			legend: {
				position: "top" as const,
				align: "center" as const,
				fullWidth: false,
				labels: {
					font: {
						size: 20,
					},
				},
				padding: 20,
			},
			maintainAspectRatio: false,
			title: {
				display: true,
				text: [`${symbol} 期權資金流`, `(合約月份: ${contractMonth})`],
				position: "top" as const,
				font: {
					size: 30,
				},
			},
		},
	};

	return (
		<>
			<Grid container justifyContent={"center"} sx={{ mt: 3 }}>
				<Grid item>
					<Typography variant="h3">期權市場資金流(美股)</Typography>
				</Grid>
			</Grid>

			<Grid
				container
				justifyContent={"center"}
				spacing={2}
				sx={{ mt: 3 }}
			>
				<MoneyFlowUsForm
					symbol={symbol}
					setSymbol={(val: string) => setSymbol(val)}
					contractMonth={contractMonth}
					setContractMonth={(val: string) => setContractMonth(val)}
				/>
			</Grid>

			{data && (
				<Grid container justifyContent={"center"} sx={{ mt: 3 }}>
					<Grid item>
						<Typography variant="h5">
							現價: {data.now_price.toFixed(2)}
						</Typography>
					</Grid>
				</Grid>
			)}

			<Grid
				container
				sx={{
					flexDirection: { xs: "column", sm: "row" },
				}}
			>
				<Grid item xs={6} sx={{ maxWidth: { xs: "100%", sm: "50%" } }}>
					{data && (
						<Doughnut
							data={percentData}
							options={percentOptions}
							// @ts-ignore
							plugins={[ChartDataLabels]}
						/>
					)}
				</Grid>
				<Grid item xs={6} sx={{ maxWidth: { xs: "100%", sm: "50%" } }}>
					{data && (
						<Bar
							data={barData}
							onDoubleClick={async () => {
								setBarModalOpen(true);
							}}
							options={barOptions}
							height={480}
							width={500}
						/>
					)}
				</Grid>
			</Grid>

			<Modal open={barModalOpen} onClose={() => setBarModalOpen(false)}>
				<Box
					sx={{
						position: "absolute" as "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: { xs: 400, sm: 1200 },
						bgcolor: "background.paper",
						border: "2px solid #fff",
						borderRadius: "10px",
						boxShadow: 24,
						p: 4,
						overflow: "scroll",
						height: { xs: 400, sm: 800 },
					}}
				>
					<Bar
						data={barAllData}
						options={barAllOptions}
						height={480}
						width={150}
					/>
				</Box>
			</Modal>
		</>
	);
};
export default MoneyFlowUsPage;
