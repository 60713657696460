import Joi from "joi";

export type OptionTableType = {
	id: string;
	side: string;
	strike: number;
	premium: number;
	quantity: number;
};

export const Option = Joi.object<OptionTableType>({
	side: Joi.string().required(),
	strike: Joi.number().greater(0).required().messages({
		"any.required": "請填寫行使價",
		"number.base": "請填寫行使價",
		"number.greater": "行使價不能是0",
	}),
	premium: Joi.number().required().messages({
		"any.required": "請填寫期權金",
		"number.base": "請填寫期權金",
	}),
	quantity: Joi.number().integer().greater(0).required().messages({
		"any.required": "請填寫數量",
		"number.base": "請填寫數量",
		"number.greater": "數量不能是0",
	}),
});
