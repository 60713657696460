import { joiResolver } from "@hookform/resolvers/joi";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { ExcelFormSchema, IExcelInput } from "../../schemas/ExcelWeb";

type IExcelForm = {
	onFormSubmit: (data: IExcelInput) => Promise<void>;
};

const ExcelForm = ({ onFormSubmit }: IExcelForm) => {
	const [isLoading, setIsLoading] = useState(false);

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<IExcelInput>({
		defaultValues: { symbol: "" },
		resolver: joiResolver(ExcelFormSchema),
	});

	const onFormSubmitHandler = async (data: IExcelInput) => {
		setIsLoading(true);
		await onFormSubmit(data);
		setIsLoading(false);
	};

	return (
		<form onSubmit={handleSubmit((data) => onFormSubmitHandler(data))}>
			<Grid container justifyContent={"center"} spacing={2}>
				<Grid item>
					<Controller
						name="symbol"
						control={control}
						render={({ field }) => (
							<TextField
								label="Symbol"
								sx={{ width: 150 }}
								{...field}
							/>
						)}
					/>
				</Grid>
				<Grid item>
					<Controller
						name="startYear"
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<Autocomplete
								options={generateYear()}
								onChange={(_, data) =>
									onChange(data ? data : "")
								}
								renderInput={(params) => {
									return (
										<TextField
											label="開始年份"
											{...field}
											{...params}
										/>
									);
								}}
								sx={{ width: 130 }}
							/>
						)}
					/>
				</Grid>
				<Grid item>
					<Controller
						name="endYear"
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<Autocomplete
								options={[
									(new Date().getFullYear() - 2).toString(),
									(new Date().getFullYear() - 1).toString(),
								]}
								onChange={(_, data) =>
									onChange(data ? data : "")
								}
								renderInput={(params) => (
									<TextField
										label="完結年份"
										{...field}
										{...params}
									/>
								)}
								sx={{ width: 130 }}
							/>
						)}
					/>
				</Grid>
				<Grid item>
					<Controller
						name="startMonth"
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<Autocomplete
								options={generateMonth()}
								onChange={(_, data) =>
									onChange(data ? data : "")
								}
								renderInput={(params) => (
									<TextField
										label="開始月份"
										{...field}
										{...params}
									/>
								)}
								sx={{ width: 110 }}
							/>
						)}
					/>
				</Grid>
				<Grid item>
					<Controller
						name="startDay"
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<Autocomplete
								options={generateDay()}
								onChange={(_, data) =>
									onChange(data ? data : "")
								}
								renderInput={(params) => (
									<TextField
										label="開始日期"
										{...field}
										{...params}
									/>
								)}
								sx={{ width: 110 }}
							/>
						)}
					/>
				</Grid>
				<Grid item>
					<Controller
						name="endDay"
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<Autocomplete
								options={generateDay()}
								onChange={(_, data) =>
									onChange(data ? data : "")
								}
								renderInput={(params) => (
									<TextField
										label="完結日期"
										{...field}
										{...params}
									/>
								)}
								sx={{ width: 110 }}
							/>
						)}
					/>
				</Grid>
				<Grid item>
					<Controller
						name="monthOver"
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<Autocomplete
								options={generateMonthOver()}
								onChange={(_, data) =>
									onChange(data ? data : "")
								}
								renderInput={(params) => (
									<TextField
										label="Month Over"
										{...field}
										{...params}
									/>
								)}
								sx={{ width: 130 }}
							/>
						)}
					/>
				</Grid>
				<Grid item>
					<Controller
						name="buyType"
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<Autocomplete
								options={["O", "H", "L", "C"]}
								onChange={(_, data) =>
									onChange(data ? data : "")
								}
								renderInput={(params) => (
									<TextField
										label="Buy Type"
										{...field}
										{...params}
									/>
								)}
								sx={{ width: 110 }}
							/>
						)}
					/>
				</Grid>
				<Grid item>
					<LoadingButton
						disabled={isLoading}
						loading={isLoading}
						size="medium"
						type="submit"
						variant="contained"
						sx={{ height: 56 }}
					>
						Submit
					</LoadingButton>
				</Grid>
			</Grid>
			<ErrorText>{errors.symbol?.message}</ErrorText>
			<ErrorText>{errors.startYear?.message}</ErrorText>
			<ErrorText>{errors.endYear?.message}</ErrorText>
			<ErrorText>{errors.startMonth?.message}</ErrorText>
			<ErrorText>{errors.startDay?.message}</ErrorText>
			<ErrorText>{errors.endDay?.message}</ErrorText>
			<ErrorText>{errors.monthOver?.message}</ErrorText>
			<ErrorText>{errors.buyType?.message}</ErrorText>
		</form>
	);
};

const generateYear = () => {
	let result = [];
	const currentYear = new Date().getFullYear() - 1;
	for (let year = currentYear; year > currentYear - 50; year--) {
		result.push(year.toString());
	}
	result.sort();
	return result;
};

const generateMonth = () => {
	let result = [];
	for (let month = 1; month < 13; month++) {
		result.push(month.toString());
	}
	return result;
};

const generateDay = () => {
	let result = [];
	for (let day = 1; day < 32; day++) {
		result.push(day.toString());
	}
	return result;
};

const generateMonthOver = () => {
	let result = [];
	for (let monthOver = 0; monthOver < 25; monthOver++) {
		result.push(monthOver.toString());
	}
	return result;
};

const ErrorText = styled.p`
	color: red;
	font-size: 20px;
	margin: 10px 0px 0px 20px;
	text-align: start;
`;

export default ExcelForm;
