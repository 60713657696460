import { Autocomplete, Grid, TextField } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { listContractMonth, listSymbol } from "../../utils/axios/moneyFlowUs";

export interface IMoneyFlowUsForm {
	symbol: string;
	setSymbol: (val: string) => void;
	contractMonth: string;
	setContractMonth: (val: string) => void;
}

const MoneyFlowUsForm = ({
	symbol,
	setSymbol,
	contractMonth,
	setContractMonth,
}: IMoneyFlowUsForm) => {
	const { data: symbolOptions } = useQuery({
		queryKey: ["moneyflowUsSymbol"],
		queryFn: ({ queryKey }) => listSymbol(null),
		refetchOnWindowFocus: false,
	});

	const { data: contractMonthOptions } = useQuery({
		queryKey: [symbol, "moneyflowUsContractMonth"],
		queryFn: async ({ queryKey }) => {
			if (queryKey[0] === "") {
				return [];
			}
			return await listContractMonth(null, queryKey[0]);
		},
		refetchOnWindowFocus: false,
	});

	return (
		<>
			<Grid item>
				<Autocomplete
					value={symbol}
					onChange={(_, newValue) => {
						setSymbol(newValue ?? "");
						setContractMonth("");
					}}
					options={
						symbolOptions?.sort((a, b) => a.localeCompare(b)) ?? []
					}
					getOptionLabel={(option) => option.toUpperCase()}
					renderInput={(params) => (
						<TextField {...params} label="指數/股票" />
					)}
					sx={{ width: 350 }}
				/>
			</Grid>
			<Grid item>
				<Autocomplete
					value={contractMonth}
					onChange={(_, newValue) => setContractMonth(newValue ?? "")}
					options={contractMonthOptions ?? []}
					renderInput={(params) => (
						<TextField {...params} label="到期日" />
					)}
					sx={{ width: 350 }}
					disabled={symbol === ""}
				/>
			</Grid>
		</>
	);
};
export default MoneyFlowUsForm;
