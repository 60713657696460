import axios from "axios";
import { IndicatorData, TIndicatorForm } from "../../schemas/Indicator";
import { TSmaCrossForm } from "../../schemas/Indicator/smaCross";

const IndicatorAxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_INDICATOR_BASE_URL,
	headers: { "Content-Type": "application/json" },
});

export const getResult = async (
	data: TIndicatorForm
): Promise<IndicatorData[]> => {
	const result = await IndicatorAxiosInstance.get("/sma", { params: data });
	return result.data.data;
};

export const getSmaCrossResult = async (
	data: TSmaCrossForm
): Promise<IndicatorData[]> => {
	const result = await IndicatorAxiosInstance.get("/sma/cross", {
		params: data,
	});
	return result.data.data;
};
