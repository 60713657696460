import { Autocomplete, Grid, TextField } from "@mui/material";

interface IMoneyFlowListForm {
	expiryDate: string;
	setExpiryDate: React.Dispatch<React.SetStateAction<string>>;
	expiryDates: string[];
	amount: string;
	setAmount: React.Dispatch<React.SetStateAction<string>>;
}

const MoneyFlowListForm = ({
	expiryDate,
	setExpiryDate,
	expiryDates,
	amount,
	setAmount,
}: IMoneyFlowListForm) => {
	return (
		<Grid container justifyContent="center" spacing={2} sx={{ my: 5 }}>
			<Grid item>
				<Autocomplete
					value={expiryDate}
					disableClearable
					onChange={(_, newValue) => setExpiryDate(newValue)}
					options={expiryDates}
					renderInput={(params) => (
						<TextField {...params} label="到期日" />
					)}
					sx={{ width: 150 }}
				/>
			</Grid>
			<Grid item>
				<Autocomplete
					value={amount}
					disableClearable
					onChange={(_, newValue) => setAmount(newValue)}
					options={["20", "ALL"]}
					renderInput={(params) => (
						<TextField {...params} label="數量" />
					)}
					sx={{ width: 150 }}
				/>
			</Grid>
		</Grid>
	);
};

export default MoneyFlowListForm;
