import { z } from "zod";

export const IndicatorFormSchema = z.object({
	symbol: z.string().min(1, "required"),
	sma1: z.number({ invalid_type_error: "sma1 is required" }),
	sma2: z.number({ invalid_type_error: "sma2 is required" }),
	sma1_type: z.string(),
	sma2_type: z.string(),
	range_up: z
		.string()
		.min(1, "required")
		.regex(/^[0-9]\d*(\.\d+)?$/, "Only Positive numbers are allowed")
		.transform((val) => parseFloat(val)),
	range_down: z
		.string()
		.min(1, "required")
		.regex(/^-?[0-9]\d*(\.\d+)?$/, "Only numbers are allowed")
		.transform((val) => parseFloat(val)),
	start_offset: z
		.string()
		.min(1, "required")
		.regex(/^-?[0-9]\d*(\d+)?$/, "Only numbers are allowed")
		.transform((val) => parseInt(val)),
	end_offset: z
		.string()
		.min(1, "required")
		.regex(/^[0-9]\d*(\d+)?$/, "Only Positive numbers are allowed")
		.transform((val) => parseInt(val)),
	buy_type: z.string(),
	sell_type: z.string(),
});

export type TIndicatorForm = z.infer<typeof IndicatorFormSchema>;

export interface IndicatorData {
	buy_date: string;
	sell_date: string;
	buy_price: number;
	sell_price: number;
	max_high: number;
	min_low: number;
	max_high_volatility: number;
	min_low_volatility: number;
	abs_max_volatility: number;
	pos_end_volatility: number;
	neg_end_volatility: number;
	abs_end_volatility: number;
}
