import Joi from "joi";

export const MoneyFlowFormSchema = Joi.object({
	symbol: Joi.string().required().messages({
		"string.empty": "請輸入股票/指數代號",
	}),
});

export interface MoneyFlowReport {
	file_date: string;
	top_bears: string[];
	top_bulls: string[];
	[key: string]: {};
}

export interface MoneyFlowReportData {
	call: number;
	call_change_percent: number;
	call_in: number;
	call_time: number;
	name: string;
	put: number;
	put_change_percent: number;
	put_in: number;
	put_time: number;
	total: number;
	total_change_percent: number;
}
