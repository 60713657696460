import axios from "axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { MoneyFlowReport } from "../../schemas/MoneyFlow";
import { Response } from "../../schemas/Response";

const moneyFlowHkAxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_MONEY_FLOW_HK_BASE_URL,
	headers: { "Content-Type": "application/json" },
});

const moneyFlowUsAxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_MONEY_FLOW_US_BASE_URL,
	headers: { "Content-Type": "application/json" },
});

export const getHkValidSymbol = async (
	token: string | null
): Promise<string[]> => {
	try {
		let res;
		if (token) {
			res = await moneyFlowHkAxiosInstance.get("/v1.1/option_symbols", {
				// headers: { Authorization: `Bearer ${token}` },
			});
		} else {
			res = await moneyFlowHkAxiosInstance.get("/v1.1/option_symbols");
		}
		const data: string[] = res.data;
		return data;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return [];
	}
};

export const getHkExpiryDates = async (
	symbol: string,
	token: string | null
): Promise<string[]> => {
	try {
		let res;
		if (token) {
			res = await moneyFlowHkAxiosInstance.get(
				`/v1.1/option_contract/${symbol}`,
				{
					// headers: { Authorization: `Bearer ${token}` },
				}
			);
		} else {
			res = await moneyFlowHkAxiosInstance.get(
				`/v1.1/option_contract/${symbol}`
			);
		}
		const data: string[] = res.data;
		return data.map((date) => dayjs(date, "YYYYMMDD").format("YYYY-MM-DD"));
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return [];
	}
};

export const getHkPercentRt = async (
	symbol: string,
	expiryDate: string,
	token: string | null
) => {
	const date = dayjs(expiryDate).format("YYYYMMDD");
	try {
		let res;
		if (token) {
			res = await moneyFlowHkAxiosInstance.get(
				`/v1.1/realtime/option_value/percent/${symbol}/${date}`,
				{
					// headers: { Authorization: `Bearer ${token}` },
				}
			);
		} else {
			res = await moneyFlowHkAxiosInstance.get(
				`/v1.1/realtime/option_value/percent/${symbol}/${date}`
			);
		}
		const data = res.data;
		return data;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return {};
	}
};

export const getHkPercentYtd = async (
	token: string | null
): Promise<MoneyFlowReport> => {
	try {
		let res;
		if (token) {
			res = await moneyFlowHkAxiosInstance.get("/option_value", {
				// headers: { Authorization: `Bearer ${token}` },
			});
		} else {
			res = await moneyFlowHkAxiosInstance.get("/option_value");
		}
		const data: MoneyFlowReport = res.data;
		return data;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return { file_date: "", top_bears: [], top_bulls: [] };
	}
};

export const getHkPercentDby = async (
	token: string | null
): Promise<MoneyFlowReport> => {
	const date = dayjs().subtract(2, "day").format("YYYYMMDD");
	try {
		let res;
		if (token) {
			res = await moneyFlowHkAxiosInstance.get(`/option_value/${date}`, {
				// headers: { Authorization: `Bearer ${token}` },
			});
		} else {
			res = await moneyFlowHkAxiosInstance.get(`/option_value/${date}`);
		}
		const data: MoneyFlowReport = res.data;
		return data;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return { file_date: "", top_bears: [], top_bulls: [] };
	}
};

export const getHkBarRt = async (
	symbol: string,
	expiryDate: string,
	token: string | null
) => {
	const date = dayjs(expiryDate).format("YYYYMMDD");
	try {
		let res;
		if (token) {
			res = await moneyFlowHkAxiosInstance.get(
				`/v1.1/realtime/option_value/value/${symbol}/${date}`,
				{
					// headers: { Authorization: `Bearer ${token}` },
				}
			);
		} else {
			res = await moneyFlowHkAxiosInstance.get(
				`/v1.1/realtime/option_value/value/${symbol}/${date}`
			);
		}
		const data = res.data;
		return data;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return {};
	}
};

export const getAllHkBarRt = async (
	symbol: string,
	expiryDate: string,
	token: string | null
) => {
	const date = dayjs(expiryDate).format("YYYYMMDD");
	try {
		let res;
		if (token) {
			res = await moneyFlowHkAxiosInstance.get(
				`/v1.1/realtime/option_value/value-all/${symbol}/${date}`,
				{
					// headers: { Authorization: `Bearer ${token}` },
				}
			);
		} else {
			res = await moneyFlowHkAxiosInstance.get(
				`/v1.1/realtime/option_value/value-all/${symbol}/${date}`
			);
		}
		const data = res.data;
		return data;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return {};
	}
};

export const getHkBarYtd = async (
	symbol: string,
	expiryDate: string,
	token: string | null
) => {
	const date = dayjs(expiryDate).format("YYYYMMDD");
	try {
		let res;
		if (token) {
			res = await moneyFlowHkAxiosInstance.get(
				`/v1.1/yesterday/option_value/value/${symbol}/${date}`,
				{
					// headers: { Authorization: `Bearer ${token}` },
				}
			);
		} else {
			res = await moneyFlowHkAxiosInstance.get(
				`/v1.1/yesterday/option_value/value/${symbol}/${date}`
			);
		}
		const data = res.data;
		return data;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return {};
	}
};

export const getAllHkBarYtd = async (
	symbol: string,
	expiryDate: string,
	token: string | null
) => {
	const date = dayjs(expiryDate).format("YYYYMMDD");
	try {
		let res;
		if (token) {
			res = await moneyFlowHkAxiosInstance.get(
				`/v1.1/yesterday/option_value/value-all/${symbol}/${date}`,
				{
					// headers: { Authorization: `Bearer ${token}` },
				}
			);
		} else {
			res = await moneyFlowHkAxiosInstance.get(
				`/v1.1/yesterday/option_value/value-all/${symbol}/${date}`
			);
		}
		const data = res.data;
		return data;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return {};
	}
};

export const getUsValidSymbol = async (token: string | null) => {
	try {
		let res;
		if (token) {
			res = await moneyFlowUsAxiosInstance.get("/symbols", {
				// headers: { Authorization: `Bearer ${token}` },
			});
		} else {
			res = await moneyFlowUsAxiosInstance.get("/symbols");
		}
		const data: Response = res.data;
		return data.data;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return [];
	}
};

export const getUsExpiryDates = async (token: string | null) => {
	try {
		let res;
		if (token) {
			res = await moneyFlowUsAxiosInstance.get("/expirations", {
				// headers: { Authorization: `Bearer ${token}` },
			});
		} else {
			res = await moneyFlowUsAxiosInstance.get("/expirations");
		}
		const data: Response = res.data;
		return data.data;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return [];
	}
};

export const getUsPercentRt = async (
	symbol: string,
	expiryDate: string,
	token: string | null
) => {
	const date = dayjs(expiryDate).format("YYYYMMDD");
	try {
		let res;
		if (token) {
			res = await moneyFlowUsAxiosInstance.get("/percent", {
				// headers: { Authorization: `Bearer ${token}` },
				params: { symbol: symbol, expiration_date: date },
			});
		} else {
			res = await moneyFlowUsAxiosInstance.get("/percent", {
				params: { symbol: symbol, expiration_date: date },
			});
		}
		const data: Response = res.data;
		return data.data;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return {};
	}
};

export const getUsBarRt = async (
	symbol: string,
	expiryDate: string,
	token: string | null
) => {
	const date = dayjs(expiryDate).format("YYYYMMDD");
	try {
		let res;
		if (token) {
			res = await moneyFlowUsAxiosInstance.get("/value", {
				// headers: { Authorization: `Bearer ${token}` },
				params: { symbol: symbol, expiration_date: date },
			});
		} else {
			res = await moneyFlowUsAxiosInstance.get("/value", {
				params: { symbol: symbol, expiration_date: date },
			});
		}
		const data: Response = res.data;
		return data.data;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return {};
	}
};

export const getAllUsBarRt = async (
	symbol: string,
	expiryDate: string,
	token: string | null
) => {
	const date = dayjs(expiryDate).format("YYYYMMDD");
	try {
		let res;
		if (token) {
			res = await moneyFlowUsAxiosInstance.get("/value-all", {
				// headers: { Authorization: `Bearer ${token}` },
				params: { symbol: symbol, expiration_date: date },
			});
		} else {
			res = await moneyFlowUsAxiosInstance.get("/value-all", {
				params: { symbol: symbol, expiration_date: date },
			});
		}
		const data: Response = res.data;
		return data.data;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return {};
	}
};
