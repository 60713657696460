import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { IndicatorFormSchema, TIndicatorForm } from "../../schemas/Indicator";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import styled from "styled-components";

interface IIndicatorForm {
	onSubmitHandler: (data: TIndicatorForm) => Promise<void>;
	loading: boolean;
}

const IndicatorForm = ({ onSubmitHandler, loading }: IIndicatorForm) => {
	const {
		control,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm<TIndicatorForm>({
		resolver: zodResolver(IndicatorFormSchema),
		defaultValues: {
			symbol: "",
			sma1: 10,
			sma2: 20,
			sma1_type: "C",
			sma2_type: "C",
			buy_type: "C",
			sell_type: "C",
		},
	});

	return (
		<form onSubmit={handleSubmit(onSubmitHandler)}>
			<Grid container justifyContent="center" spacing={2}>
				<Grid item>
					<Controller
						name="symbol"
						control={control}
						render={({ field }) => (
							<TextField label="Symbol" {...field} />
						)}
					/>
					<ErrorText>{errors?.symbol?.message}</ErrorText>
				</Grid>
				<Grid item>
					<Controller
						name="sma1"
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<TextField
								label="sma1"
								onChange={(e) =>
									onChange(parseInt(e.target.value))
								}
								type="number"
								sx={{ width: 113 }}
								{...field}
							/>
						)}
					/>
					<ErrorText>{errors?.sma1?.message}</ErrorText>
				</Grid>
				<Grid item>
					<Controller
						name="sma2"
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<TextField
								label="sma2"
								onChange={(e) =>
									onChange(parseInt(e.target.value))
								}
								type="number"
								sx={{ width: 113 }}
								{...field}
							/>
						)}
					/>
					<ErrorText>{errors?.sma2?.message}</ErrorText>
				</Grid>
				<Grid item>
					<Controller
						name="sma1_type"
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<Autocomplete
								options={["O", "H", "L", "C"]}
								value={getValues().sma1_type}
								onChange={(_, data) =>
									onChange(data ? data : "")
								}
								renderInput={(params) => (
									<TextField
										label="sma1 type"
										{...field}
										{...params}
									/>
								)}
							/>
						)}
					/>
					<ErrorText>{errors?.sma1_type?.message}</ErrorText>
				</Grid>
				<Grid item>
					<Controller
						name="sma2_type"
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<Autocomplete
								options={["O", "H", "L", "C"]}
								value={getValues().sma2_type}
								onChange={(_, data) =>
									onChange(data ? data : "")
								}
								renderInput={(params) => (
									<TextField
										label="sma2 type"
										{...field}
										{...params}
									/>
								)}
							/>
						)}
					/>
					<ErrorText>{errors?.sma2_type?.message}</ErrorText>
				</Grid>
				<Grid item>
					<Controller
						name="range_up"
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<TextField
								label="range up"
								placeholder="e.g. 0.0014"
								onChange={(e) => onChange(e.target.value)}
								sx={{ width: 113 }}
								{...field}
							/>
						)}
					/>
					<ErrorText>{errors?.range_up?.message}</ErrorText>
				</Grid>
				<Grid item>
					<Controller
						name="range_down"
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<TextField
								label="range down"
								placeholder="e.g. -0.002"
								onChange={(e) => {
									onChange(e.target.value);
								}}
								sx={{ width: 113 }}
								{...field}
							/>
						)}
					/>
					<ErrorText>{errors?.range_down?.message}</ErrorText>
				</Grid>
				<Grid item>
					<Controller
						name="start_offset"
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<TextField
								label="start offset"
								placeholder="-3"
								onChange={(e) => onChange(e.target.value)}
								sx={{ width: 113 }}
								{...field}
							/>
						)}
					/>
					<ErrorText>{errors?.start_offset?.message}</ErrorText>
				</Grid>
				<Grid item>
					<Controller
						name="end_offset"
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<TextField
								label="end offset"
								placeholder="15"
								onChange={(e) => onChange(e.target.value)}
								sx={{ width: 113 }}
								{...field}
							/>
						)}
					/>
					<ErrorText>{errors?.end_offset?.message}</ErrorText>
				</Grid>
				<Grid item>
					<Controller
						name="buy_type"
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<Autocomplete
								options={["O", "H", "L", "C"]}
								value={getValues().buy_type}
								onChange={(_, data) =>
									onChange(data ? data : "")
								}
								renderInput={(params) => (
									<TextField
										label="buy type"
										{...field}
										{...params}
									/>
								)}
							/>
						)}
					/>
					<ErrorText>{errors?.buy_type?.message}</ErrorText>
				</Grid>
				<Grid item>
					<Controller
						name="sell_type"
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<Autocomplete
								options={["O", "H", "L", "C"]}
								value={getValues().sell_type}
								onChange={(_, data) =>
									onChange(data ? data : "")
								}
								renderInput={(params) => (
									<TextField
										label="sell type"
										{...field}
										{...params}
									/>
								)}
							/>
						)}
					/>
					<ErrorText>{errors?.sell_type?.message}</ErrorText>
				</Grid>
				<Grid item>
					<LoadingButton
						disabled={loading}
						loading={loading}
						type="submit"
						variant="contained"
						sx={{ height: 56 }}
					>
						Submit
					</LoadingButton>
				</Grid>
			</Grid>
		</form>
	);
};

const ErrorText = styled.p`
	color: red;
	font-size: 15px;
	margin-left: 5px;
	text-align: start;
`;

export default IndicatorForm;
