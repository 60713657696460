import Joi from "joi";
import { z } from "zod";

export type ExcelWebType = {
	year: string;
	real_start_date: string;
	real_end_date: string;
	buy_price: number;
	sell_price: number;
	max_high: number;
	min_low: number;
	max_high_volatility: number;
	min_low_volatility: number;
	abs_max_volatility: number;
	pos_end_volatility: number;
	neg_end_volatility: number;
	abs_end_volatility: number;
	max_high_date: string;
	min_low_date: string;
	abs_max_date: string;
};

export interface IExcelInput {
	symbol: string;
	startYear: number;
	endYear: number;
	startMonth: number;
	startDay: number;
	endDay: number;
	monthOver: number;
	buyType: string;
}

export const ExcelFormSchema = Joi.object({
	symbol: Joi.string().required().messages({
		"string.empty": "請填寫Symbol",
	}),
	startYear: Joi.number().integer().required().messages({
		"any.required": "請填寫開始年份",
		"number.base": "請填寫開始年份",
	}),
	endYear: Joi.number().integer().required().messages({
		"any.required": "請填寫完結年份",
		"number.base": "請填寫完結年份",
	}),
	startMonth: Joi.number().integer().required().messages({
		"any.required": "請填寫開始月份",
		"number.base": "請填寫開始月份",
	}),
	startDay: Joi.number().integer().required().messages({
		"any.required": "請填寫開始日期",
		"number.base": "請填寫開始日期",
	}),
	endDay: Joi.number().integer().required().messages({
		"any.required": "請填寫完結日期",
		"number.base": "請填寫完結日期",
	}),
	monthOver: Joi.number().integer().required().messages({
		"any.required": "請填寫Month Over",
		"number.base": "請填寫Month Over",
	}),
	buyType: Joi.string().required().messages({
		"any.required": "請填寫Buy Type",
		"string.empty": "請填寫Buy Type",
	}),
});

export interface ExcelWebMonthType extends ExcelWebType {
	code: string;
	exptected_abs_max: number;
}

export const ExcelMonthFormSchema = z.object({
	symbol: z.string().min(1, "Symbol is required"),
	monthOver: z.number().min(0).max(11),
	volaType: z.union([
		z.literal("升波幅"),
		z.literal("跌波幅"),
		z.literal("最大波幅"),
	]),
});
export type TExcelMonthForm = z.infer<typeof ExcelMonthFormSchema>;
