import axios from "axios";
import { toast } from "react-toastify";
import { Response } from "../../schemas/Response";
import { MoneyFlowHistoryType } from "../../schemas/MoneyFlowHistory";

const moneyFlowHistoryAxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_MONEY_FLOW_HISTORY_BASE_URL,
	headers: { "Content-Type": "application/json" },
});

export const getMoneyFlowHistorySymbolOptions = async (
	token: string | null
): Promise<string[]> => {
	try {
		let res;
		if (token) {
			res = await moneyFlowHistoryAxiosInstance.get("/symbols", {
				// headers: {
				// 	Authorization: `Bearer ${token}`,
				// },
			});
		} else {
			res = await moneyFlowHistoryAxiosInstance.get("/symbols");
		}
		const data: Response = res.data;
		if (data.status !== 200) {
			toast.error("發生錯誤");
			console.error(
				`Error in getting symbol options. Error message: ${data.message}`
			);
			return [];
		}
		const symbolOptions: string[] = data.data;
		return symbolOptions;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return [];
	}
};

export const getMoneyFlowHistoryData = async (
	symbol: string | null,
	startDate: string | undefined,
	contractMonth: string | null,
	token: string | null
): Promise<MoneyFlowHistoryType> => {
	try {
		let res;
		if (token) {
			res = await moneyFlowHistoryAxiosInstance.get("", {
				params: {
					symbol: symbol,
					start_date: startDate,
					contract_month: contractMonth,
				},
				// headers: {
				// 	Authorization: `Bearer ${token}`,
				// },
			});
		} else {
			res = await moneyFlowHistoryAxiosInstance.get("", {
				params: {
					symbol: symbol,
					start_date: startDate,
					contract_month: contractMonth,
				},
			});
		}
		const data: Response = await res.data;
		if (data.status !== 200) {
			toast.error("發生錯誤");
			console.error(
				`Error in getting ${symbol} option history. Error message: ${data.message}`
			);
			return {
				label: [],
				call_in: [],
				call_time: [],
				put_in: [],
				put_time: [],
			};
		}
		toast.success("成功");
		const moneyFlowHistory: MoneyFlowHistoryType = data.data;
		return moneyFlowHistory;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return {
			label: [],
			call_in: [],
			call_time: [],
			put_in: [],
			put_time: [],
		};
	}
};
