import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { OptionTableType } from "../../schemas/OptionTable";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const lineOptions = {
	responsive: true,
	plugins: {
		legend: {
			display: false,
		},
		title: {
			display: false,
		},
	},
};

interface IOptionTable {
	data: OptionTableType[];
	price: string;
	range: string;
}

const OptionTable = ({ data, price, range }: IOptionTable) => {
	const [options, setOptions] = useState<number[][]>([]);
	const [lineDataset, setLineDataset] = useState<number[]>([]);

	const lineData = {
		labels: generateStrikes(parseFloat(price), parseFloat(range)).sort(),
		datasets: [
			{
				label: "Dataset 1",
				data: lineDataset,
				borderColor: "rgb(255, 99, 132)",
				backgroundColor: "rgba(255, 99, 132, 0.5)",
			},
		],
	};

	useEffect(() => {
		let options: number[][] = [];
		let lineData: number[] = [];
		if (price === "" || range === "") return;
		const strikes = generateStrikes(parseFloat(price), parseFloat(range))
			.sort()
			.reverse();
		const cost = _.reduce(
			data,
			(sum, option) => sum + option.premium * option.quantity,
			0
		);

		strikes.forEach((strike) => {
			let profit: number[] = [];
			data.forEach((option) => {
				if (option.side === "Call") {
					if (strike > option.strike) {
						const side = option.premium < 0 ? 1 : -1;
						profit.push(
							(strike - option.strike) * side * option.quantity
						);
					} else {
						profit.push(0);
					}
				} else {
					if (strike < option.strike) {
						const side = option.premium < 0 ? 1 : -1;
						profit.push(
							(option.strike - strike) * side * option.quantity
						);
					} else {
						profit.push(0);
					}
				}
			});

			const totalProfit = _.reduce(
				profit,
				(sum, value) => sum + value,
				0
			);

			options.push([strike, ...profit, cost, totalProfit + cost]);
			lineData.push(totalProfit + cost);
		});
		setOptions(options);
		setLineDataset(lineData.reverse());
	}, [data, price, range]);

	return (
		<>
			<TableContainer sx={{ mt: 5, mb: 10 }}>
				<Table sx={{ width: { xs: "max-width" } }}>
					<TableHead>
						<TableRow>
							<TableCell align="center">價格表</TableCell>
							{data.map((option) => (
								<TableCell
									key={`${option.side}${option.strike}`}
									align="center"
								>
									{option.premium > 0 ? "Short " : "Long "}
									{`${option.side} 行使價 ${option.strike}`}
								</TableCell>
							))}
							<TableCell align="center">
								付出 / 收取期權金總數
							</TableCell>
							<TableCell align="center">Sum</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{options.map((option) => (
							<TableRow key={option[0]}>
								{option.map((item, index) => (
									<TableCell key={index} align="center">
										{item}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Line options={lineOptions} data={lineData} />
		</>
	);
};

const generateStrikes = (price: number, range: number): number[] => {
	let strikes = [price];
	for (let i = 1; i <= 10; i++) {
		strikes.push(price + i * range);
		strikes.push(price - i * range);
	}
	return strikes;
};

export default OptionTable;
