import {
	Autocomplete,
	Button,
	Grid,
	TextField,
	Typography,
} from "@mui/material";
import { Bar, Doughnut } from "react-chartjs-2";
import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	Tooltip,
	Title,
	ArcElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useState } from "react";
import { MoneyFlowTickData } from "../../schemas/FuturesMoneyFlow";
import _ from "lodash";
import {
	getFuturesData,
	getFuturesT2Data,
} from "../../utils/axios/moneyFlowFutures";

ChartJS.register(
	ArcElement,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const findStartEndIndex = (
	data: number[]
): { startIdx: number; endIdx: number } => {
	let startIdx: number = 0;
	let endIdx: number = data.length;
	for (let i = 0; i < data.length; i++) {
		if (data[i] !== 0) {
			startIdx = i;
			break;
		}
	}
	for (let i = data.length - 1; i >= 0; i--) {
		if (data[i] !== 0) {
			endIdx = i;
			break;
		}
	}
	return { startIdx, endIdx };
};

const findClosestStrikePrice = (
	strikes: number[],
	price: number
): { leftIdx: number; rightIdx: number } => {
	for (let i = 0; i < strikes.length; i++) {
		if (strikes[i] >= price) return { leftIdx: i - 1, rightIdx: i };
	}
	return { leftIdx: strikes.length / 2, rightIdx: strikes.length / 2 + 1 };
};

const FuturesMoneyFlowPage = () => {
	const [nowPrice, setNowPrice] = useState(0);
	const [strikes, setStrikes] = useState<number[]>([]);
	const [updatedAt, setUpdatedAt] = useState("");
	const [breakeven, setBreakeven] = useState(0);
	const [tickDataUp, setTickDataUp] = useState<number[]>([]);
	const [tickDataDown, setTickDataDown] = useState<number[]>([]);
	const [tickDataBreakeven, setTickDataBreakeven] = useState<number[]>([]);
	const [mode, setMode] = useState("即市");
	const [symbol, setSymbol] = useState("HSI");
	const [submit, setSubmit] = useState(false);

	const getTickData = async () => {
		const jwt = localStorage.getItem("token");

		let data: MoneyFlowTickData;
		if (mode === "T+2") data = await getFuturesT2Data(symbol, jwt);
		else data = await getFuturesData(symbol, jwt);

		setNowPrice(data.now_price);
		setUpdatedAt(data.updated_at);
		setBreakeven(data.breakeven);
		const { startIdx, endIdx } = findStartEndIndex(data.data);
		const start = Math.max(0, startIdx - 5);
		const end = Math.min(data.data.length, endIdx + 5);
		setStrikes(data.strike_prices.slice(start, end));
		const { rightIdx } = findClosestStrikePrice(
			data.strike_prices,
			data.now_price
		);
		const { leftIdx } = findClosestStrikePrice(
			data.strike_prices,
			data.breakeven
		);
		let tdu = _.fill([...data.data], 0, start, rightIdx);
		let tdd = _.fill([...data.data], 0, rightIdx, end);
		tdu[leftIdx] = 0;
		tdd[leftIdx] = 0;
		let tdb = new Array(data.data.length).fill(0);
		tdb[leftIdx] = data.data[leftIdx];
		setTickDataUp(tdu.slice(start, end));
		setTickDataDown(tdd.slice(start, end));
		setTickDataBreakeven(tdb.slice(start, end));

		setTimeout(getTickData, 3000);
	};

	const onSubmitHandler = async () => {
		setSubmit(true);

		await getTickData();
	};
	const barData = {
		labels: strikes,
		datasets: [
			{
				label: "好倉",
				data: tickDataDown,
				borderColor: "rgba(255, 255, 255, 1)",
				backgroundColor: "rgba(0, 115, 230, 1)",
				barPercentage: 1,
			},
			{
				label: "淡倉",
				data: tickDataUp,
				borderColor: "rgba(255, 255, 255, 1)",
				backgroundColor: "rgba(0, 176, 80, 1)",
				barPercentage: 1,
			},
			{
				label: "打和點",
				data: tickDataBreakeven,
				borderColor: "rgba(255, 255, 255, 1)",
				backgroundColor: "rgba(102, 0, 51, 1)",
				barPercentage: 1,
			},
		],
	};

	const barOptions = {
		animation: {
			duration: 0,
		},
		indexAxis: "y" as const,
		scales: {
			x: {
				title: {
					display: true,
					text: [
						`${symbol}期貨現價: ${nowPrice}`,
						`打和點: ${breakeven}`,
						`最後更新: ${updatedAt}`,
					],
					font: { size: 20 },
					padding: { top: 20 },
				},
			},
			y: {
				reverse: true,
				stacked: true,
			},
		},
		plugins: {
			legend: {
				position: "right" as const,
				align: "end" as const,
				fullWidth: false,
				labels: {
					font: {
						size: 20,
					},
				},
				padding: 20,
			},
			maintainAspectRatio: false,
		},
	};

	const doughnutData = {
		labels: ["好倉", "淡倉"],
		datasets: [
			{
				data: [_.sum(tickDataDown), _.sum(tickDataUp)],
				backgroundColor: [
					"rgba(0, 115, 230, 1)",
					"rgba(0, 176, 80, 1)",
				],
				borderColor: "rgba(255, 255, 255, 1)",
				borderWidth: 1,
				weight: 1,
			},
		],
	};

	const doughnutOptions = {
		animation: {
			duration: 0,
		},
		cutout: "60%",
		maintainAspectRatio: false,
		plugins: {
			datalabels: {
				color: "#000000",
				font: { size: 15 },
				formatter: (value: number) =>
					`${Math.round(
						(value / (_.sum(tickDataDown) + _.sum(tickDataUp))) *
							100
					)}%`,
			},
			legend: {
				position: "right" as const,
				align: "end" as const,
				labels: {
					boxWidth: 40,
					font: { size: 20 },
					padding: 20,
				},
			},
			title: {
				display: true,
				text: [
					`${symbol}期貨現價: ${nowPrice}`,
					`打和點: ${breakeven}`,
					`最後更新: ${updatedAt}`,
				],
				font: { size: 20, weight: "normal" },
				padding: { top: 0 },
			},
		},
	};

	return (
		<>
			<Grid container justifyContent={"center"} sx={{ mt: 3 }}>
				<Grid item>
					<Typography variant="h3">大戶盤路資金表</Typography>
				</Grid>
			</Grid>

			<Grid container justifyContent="center" spacing={2} sx={{ mt: 5 }}>
				<Grid item>
					<Autocomplete
						disabled={submit}
						disableClearable={true}
						value={symbol}
						onChange={(_, newValue) => setSymbol(newValue)}
						options={["HSI", "HHI.HK", "HSTECH"]}
						renderInput={(params) => (
							<TextField {...params} label="指數" />
						)}
						sx={{ width: 150 }}
					/>
				</Grid>

				<Grid item>
					<Autocomplete
						disabled={submit}
						disableClearable={true}
						value={mode}
						onChange={(_, newValue) => setMode(newValue)}
						options={["即市", "T+2"]}
						renderInput={(params) => (
							<TextField {...params} label="時段" />
						)}
						sx={{ width: 150 }}
					/>
				</Grid>

				{!submit && (
					<Grid item>
						<Button
							style={{ height: 55 }}
							type="submit"
							variant="contained"
							onClick={onSubmitHandler}
						>
							提交
						</Button>
					</Grid>
				)}

				{submit && (
					<Grid item>
						<Button
							style={{ height: 55 }}
							onClick={() => window.location.reload()}
							variant="contained"
						>
							重置
						</Button>
					</Grid>
				)}
			</Grid>

			{!_.isEmpty(strikes) && (
				<Grid container justifyContent={"center"} sx={{ mt: 4 }}>
					<Grid
						item
						xs={6}
						sx={{ maxWidth: { xs: "100%", sm: "50%" } }}
					>
						<Doughnut
							data={doughnutData}
							options={doughnutOptions}
							plugins={[ChartDataLabels]}
						/>
					</Grid>
					<Grid
						item
						xs={6}
						sx={{ maxWidth: { xs: "100%", sm: "50%" } }}
					>
						<Bar
							data={barData}
							options={barOptions}
							height={400}
							width={500}
						/>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default FuturesMoneyFlowPage;
