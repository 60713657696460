import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { periodOptions } from "./moneyFlowHistory.contstants";
import {
	getMoneyFlowHistoryData,
	getMoneyFlowHistorySymbolOptions,
} from "../../utils/axios/moneyFlowHistory";

interface IMoneyFlowHistoryForm {
	setLabel: React.Dispatch<React.SetStateAction<string[]>>;
	setCallIn: React.Dispatch<React.SetStateAction<number[]>>;
	setCallTime: React.Dispatch<React.SetStateAction<number[]>>;
	setPutIn: React.Dispatch<React.SetStateAction<number[]>>;
	setPutTime: React.Dispatch<React.SetStateAction<number[]>>;
}

const MoneyFlowHistoryForm = ({
	setLabel,
	setCallIn,
	setCallTime,
	setPutIn,
	setPutTime,
}: IMoneyFlowHistoryForm) => {
	const [symbol, setSymbol] = useState<string | null>(null);
	const [symbolOptions, setSymbolOptions] = useState<string[]>([]);
	const [startDate, setStartDate] = useState<string | undefined>(
		dayjs().format("YYYY-MM-DD")
	);
	const [contractMonth, setContractMonth] = useState<string | null>(null);

	const getSymbolOptions = useCallback(async () => {
		const jwt = localStorage.getItem("token");
		setSymbolOptions(await getMoneyFlowHistorySymbolOptions(jwt));
	}, []);

	const onFormSubmitHandler = async () => {
		const jwt = localStorage.getItem("token");
		const moneyFlowHistory = await getMoneyFlowHistoryData(
			symbol,
			startDate,
			contractMonth,
			jwt
		);
		setLabel(moneyFlowHistory.label);
		setCallIn(moneyFlowHistory.call_in.map((ci) => ci * 100));
		setCallTime(moneyFlowHistory.call_time.map((ct) => ct * 100));
		setPutIn(moneyFlowHistory.put_in.map((pi) => pi * 100));
		setPutTime(moneyFlowHistory.put_time.map((pt) => pt * 100));
	};

	useEffect(() => {
		getSymbolOptions();
	}, [getSymbolOptions]);

	return (
		<Grid container justifyContent="center" spacing={2} sx={{ mt: 5 }}>
			<Grid item>
				<Autocomplete
					value={symbol}
					onChange={(_, newValue) => setSymbol(newValue)}
					options={symbolOptions}
					renderInput={(params) => (
						<TextField {...params} label="指數/股票" />
					)}
					sx={{ width: 350 }}
				/>
			</Grid>
			<Grid item>
				<Autocomplete
					getOptionLabel={(option) => option.label}
					onChange={(_, newValue) => setStartDate(newValue?.value)}
					options={periodOptions}
					renderInput={(params) => (
						<TextField {...params} label="時段" />
					)}
					sx={{ width: 350 }}
				/>
			</Grid>
			<Grid item>
				<Autocomplete
					value={contractMonth}
					onChange={(_, newValue) => setContractMonth(newValue)}
					options={generateContractMonthOptions()}
					renderInput={(params) => (
						<TextField {...params} label="合約月份" />
					)}
					sx={{ width: 350 }}
				/>
			</Grid>
			<Grid item>
				<Button
					style={{ height: 56 }}
					type="submit"
					variant="contained"
					onClick={onFormSubmitHandler}
				>
					Submit
				</Button>
			</Grid>
		</Grid>
	);
};

const generateContractMonthOptions = (): string[] => {
	let contractMonthOptions = [];
	for (let i = 0; i < 2; i++) {
		contractMonthOptions.push(dayjs().add(i, "month").format("YYYYMM"));
	}
	return contractMonthOptions;
};

export default MoneyFlowHistoryForm;
