import axios from "axios";
import { toast } from "react-toastify";
import { JWT } from "../../schemas/Login";
import { Response } from "../../schemas/Response";

const AuthAxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_AUTH_BASE_URL,
	headers: { "Content-Type": "application/json" },
});

export const login = async (id: string, phone: string): Promise<boolean> => {
	try {
		const res = await AuthAxiosInstance.post("/main/web/login", {
			telegram_id: id,
			phone: phone,
		});
		const data: Response = res.data;
		if (data.status !== 200) {
			toast.error("Telegram ID/電話號碼 錯誤");
			console.error(data);
			return false;
		}
		toast.success("成功登入");
		// save jwt to local storage
		const jwt: JWT = data.data;
		localStorage.setItem("token", jwt.jwt);
		return true;
	} catch (error) {
		toast.error("發生錯誤");
		console.error(error);
		return false;
	}
};
