import axios from "axios";
import { toast } from "react-toastify";
import { MoneyFlowTickData } from "../../schemas/FuturesMoneyFlow";

const moneyFlowFuturesAxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_MONEY_FLOW_FUTURES_BASE_URL,
	headers: { "Content-Type": "application/json" },
});

export const getFuturesData = async (symbol: string, token: string | null) => {
	try {
		let res;
		if (token) {
			res = await moneyFlowFuturesAxiosInstance.get("", {
				params: { symbol: symbol },
				// headers: { Authorization: `Bearer ${token}` },
			});
		} else {
			res = await moneyFlowFuturesAxiosInstance.get("", {
				params: { symbol: symbol },
			});
		}
		const data: MoneyFlowTickData = res.data;
		return data;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return {} as MoneyFlowTickData;
	}
};

export const getFuturesT2Data = async (
	symbol: string,
	token: string | null
) => {
	try {
		let res;
		if (token) {
			res = await moneyFlowFuturesAxiosInstance.get("/t2", {
				params: { symbol: symbol },
				// headers: { Authorization: `Bearer ${token}` },
			});
		} else {
			res = await moneyFlowFuturesAxiosInstance.get("/t2", {
				params: { symbol: symbol },
			});
		}
		const data: MoneyFlowTickData = res.data;
		return data;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return {} as MoneyFlowTickData;
	}
};
