import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { listDividend } from "../utils/axios/dividend";
import { isNil } from "lodash";
import { useMemo, useState } from "react";
import {
	DataGridPremium,
	GridColDef,
	GridToolbar,
} from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router-dom";

const DividendPage = () => {
	const [expiryDate, setExpiryDate] = useState("");

	const navigate = useNavigate();

	const { data, isLoading, isFetching } = useQuery({
		queryKey: ["listDividend"],
		queryFn: ({ queryKey }) => listDividend(null),
		refetchOnWindowFocus: false,
	});

	useMemo(() => {
		if (isNil(data)) {
			return;
		}
		setExpiryDate(Object.keys(data)[0]);
	}, [data]);

	const filteredData = useMemo(() => {
		if (isNil(data) || expiryDate === "") {
			return;
		}
		return data[expiryDate];
	}, [data, expiryDate]);

	const callColumns: GridColDef[] = [
		{
			field: "NAME",
			align: "center",
			headerAlign: "center",
			headerName: "正股名稱",
			width: 120,
			flex: 1.2,
		},
		{
			field: "SYMBOL",
			align: "center",
			headerAlign: "center",
			headerName: "正股號碼",
			width: 80,
		},
		{
			field: "NOW PRICE",
			align: "center",
			headerAlign: "center",
			headerName: "現價",
			width: 70,
		},
		{
			field: "EXPIRY DATE",
			align: "center",
			headerAlign: "center",
			headerName: "到期日",
			width: 100,
		},
		{
			field: "DIVIDEND",
			align: "center",
			headerAlign: "center",
			headerName: "息率",
			valueFormatter: (params) => `${(params.value * 100).toFixed(2)}%`,
			width: 80,
		},
		{
			field: "STRIKE PRICE",
			align: "center",
			headerAlign: "center",
			headerName: "行使價",
			width: 80,
		},
		{
			field: "action",
			align: "center",
			headerAlign: "center",
			headerName: "做法",
			width: 280,
			renderCell: (params) => (
				<div>
					正股 + Short Call {params.row.NAME} @{" "}
					{params.row["STRIKE PRICE"]}
				</div>
			),
		},
	];

	const putColumns: GridColDef[] = [
		{
			field: "action",
			align: "center",
			headerAlign: "center",
			headerName: "做法",
			width: 230,
			renderCell: (params) => (
				<div>
					Short Put {params.row.NAME} @ {params.row["STRIKE PRICE"]}
				</div>
			),
		},
		{
			field: "STRIKE PRICE",
			align: "center",
			headerAlign: "center",
			headerName: "行使價",
			width: 80,
		},
		{
			field: "DIVIDEND",
			align: "center",
			headerAlign: "center",
			headerName: "息率",
			valueFormatter: (params) => `${(params.value * 100).toFixed(2)}%`,
			width: 80,
		},
		{
			field: "EXPIRY DATE",
			align: "center",
			headerAlign: "center",
			headerName: "到期日",
			width: 100,
		},
		{
			field: "NOW PRICE",
			align: "center",
			headerAlign: "center",
			headerName: "現價",
			width: 70,
		},
		{
			field: "SYMBOL",
			align: "center",
			headerAlign: "center",
			headerName: "正股號碼",
			width: 80,
		},
		{
			field: "NAME",
			align: "center",
			headerAlign: "center",
			headerName: "正股名稱",
			width: 120,
			flex: 1.2,
		},
	];

	return (
		<>
			<Grid container justifyContent={"center"} sx={{ mt: 3 }}>
				<Grid item>
					<Typography variant="h3">期權息率表</Typography>
				</Grid>
			</Grid>

			{isNil(data) ? null : (
				<Grid container justifyContent="center" sx={{ mt: 3 }}>
					<Autocomplete
						value={expiryDate}
						disableClearable
						onChange={(_, newValue) => setExpiryDate(newValue)}
						options={Object.keys(data)}
						renderInput={(params) => (
							<TextField {...params} label="到期日" />
						)}
						sx={{ width: 150 }}
					/>
				</Grid>
			)}

			<Box
				sx={{
					display: "flex",
					flexDirection: { xs: "column", sm: "row" },
				}}
			>
				<Box sx={{ mt: 5, width: { xs: "100%" } }}>
					<Typography textAlign="center" variant="h4">
						Naked Short Put
					</Typography>
					<DataGridPremium
						autoHeight
						rows={filteredData?.put ?? []}
						columns={putColumns}
						loading={isNil(filteredData) || isLoading || isFetching}
						getRowId={(row) => row.SYMBOL}
						hideFooter={true}
						initialState={{
							sorting: {
								sortModel: [
									{ field: "DIVIDEND", sort: "desc" },
								],
							},
						}}
						onRowDoubleClick={(params) =>
							navigate(params.id.toString())
						}
						sx={{
							".MuiDataGrid-cell": {
								textAlign: "center",
								whiteSpace: "normal !important",
							},
						}}
						components={{ Toolbar: GridToolbar }}
						componentsProps={{
							toolbar: {
								showQuickFilter: true,
								quickFilterProps: { debounceMs: 500 },
							},
						}}
					/>
				</Box>
				<Box sx={{ mt: 5, width: { xs: "100%" } }}>
					<Typography textAlign="center" variant="h4">
						Covered Call
					</Typography>
					<DataGridPremium
						autoHeight
						rows={filteredData?.call ?? []}
						columns={callColumns}
						loading={isNil(filteredData) || isLoading || isFetching}
						getRowId={(row) => row.SYMBOL}
						hideFooter={true}
						initialState={{
							sorting: {
								sortModel: [
									{ field: "DIVIDEND", sort: "desc" },
								],
							},
						}}
						onRowDoubleClick={(params) =>
							navigate(params.id.toString())
						}
						sx={{
							".MuiDataGrid-cell": {
								textAlign: "center",
								whiteSpace: "normal !important",
							},
						}}
						components={{ Toolbar: GridToolbar }}
						componentsProps={{
							toolbar: {
								showQuickFilter: true,
								quickFilterProps: { debounceMs: 500 },
							},
						}}
					/>
				</Box>
			</Box>
		</>
	);
};
export default DividendPage;
