import { Typography } from "@mui/material";

const DisclaimerPage = () => (
	<Typography variant="h5" sx={{ mt: 5 }}>
		免責聲明及用戶注意事項 本公司的產品(Quant Raiser
		Web)並未在香港持有就期貨合約及證券提供意見的牌照，及沒有任何牌照以進行在《證券及期貨條例》下的受規管活動。本公司提供的課程或講座均為教育目的而開設，導師、嘉賓或參與者的言論純屬個人意見，並非投資建議，亦不構成任何證券、期貨或期權買賣之邀請或要約。本公司力求資料準確，但對資料的準確性和可靠性不作任何保證或承擔。所有資料僅作參考，如引致任何損失，本公司及董事均不負上任何責任。
		買賣證券可能會招致損失而非利潤。過往表現，未必對未來表現有指示作用。買賣期貨或期權合約的虧蝕風險可以極大，在若干情況下，期貨及期權投資者所蒙受的虧蝕可能會超過最初存入的保證金數額。即使設定了備用指示，例如“止蝕”或“限價”，亦未必能夠避免損失，因為市場情況可能使該等指示無法被執行。投資者可能會在短時間內被要求存入額外的保證金。假如未能在指定的時間內提供所需數額，持有的未平倉合約可能會被強制平倉而投資者仍需要為賬戶內出現的短欠數額負責。投資者須根據本身的投資目標及個人財務狀況決定任何策略或產品或服務是否合適。如有疑問，應咨詢專業顧問。
		注意： (Quant Raiser Web) 內容受香港版權保護，如未經作者(Quant
		Raiser)書面同意，而作任何形式或媒體免費複製和分發，或作個人用途或供機構內部使用均一律禁止，並即時會取消(Quantraiser
		Web)的用戶記錄，所付的年費不會退還。
	</Typography>
);
export default DisclaimerPage;
