import axios from "axios";
import { toast } from "react-toastify";
import {
	ExcelWebMonthType,
	ExcelWebType,
	IExcelInput,
} from "../../schemas/ExcelWeb";
import { Response } from "../../schemas/Response";

const ExcelWebAxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_EXCEL_WEB_BASE_URL,
	headers: { "Content-Type": "application/json" },
});

export const getExcelWebData = async (
	inputData: IExcelInput,
	token: string | null
): Promise<ExcelWebType[]> => {
	try {
		let res;
		if (token) {
			res = await ExcelWebAxiosInstance.get(
				`/${inputData.symbol}/${inputData.startYear}/${inputData.endYear}/${inputData.startMonth}/${inputData.startDay}/${inputData.endDay}/${inputData.monthOver}/${inputData.buyType}`
				// {
				// 	headers: {
				// 		Authorization: `Bearer ${token}`,
				// 	},
				// }
			);
		} else {
			res = await ExcelWebAxiosInstance.get(
				`/${inputData.symbol}/${inputData.startYear}/${inputData.endYear}/${inputData.startMonth}/${inputData.startDay}/${inputData.endDay}/${inputData.monthOver}/${inputData.buyType}`
			);
		}
		const data: Response = res.data;
		if (data.status !== 200) {
			if (data.code === 100) {
				toast.error(`不支援 Symbol: ${inputData.symbol}`);
				console.error(`${data.message} ${inputData.symbol}`);
			} else {
				toast.error("發生錯誤");
				console.error(data);
			}
			return [];
		}
		toast.success("成功");
		const excelWebType: ExcelWebType[] = data.data;
		return excelWebType;
	} catch (error) {
		toast.error("請檢查輸入");
		console.error("Fetch api error: " + error);
		return [];
	}
};

export const getExcelWebMonthData = async (
	symbol: string,
	monthOver: number,
	volaType: string,
	token: string | null
): Promise<ExcelWebMonthType[]> => {
	try {
		let res;
		if (token) {
			res = await ExcelWebAxiosInstance.get(
				`/month/${symbol}/${monthOver}/${volaType}`
				// {
				// 	headers: {
				// 		Authorization: `Bearer ${token}`,
				// 	},
				// }
			);
		} else {
			res = await ExcelWebAxiosInstance.get(
				`/month/${symbol}/${monthOver}/${volaType}`
			);
		}
		const data: Response = res.data;
		if (data.status !== 200) {
			if (data.code === 100) {
				toast.error(`不支援 Symbol: ${symbol}`);
				console.error(`${data.message} ${symbol}`);
			} else {
				toast.error("發生錯誤");
				console.error(data);
			}
			return [];
		}
		toast.success("成功");
		const excelWebType: ExcelWebMonthType[] = data.data;
		return excelWebType;
	} catch (error) {
		toast.error("請檢查輸入");
		console.error("Fetch api error: " + error);
		return [];
	}
};
