import axios from "axios";
import { toast } from "react-toastify";
import {
	InPriceData,
	InPriceInverseData,
	QuoteData,
} from "../../schemas/Quotes/quotes";

const moneyFlowQuotesAxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_MONEY_FLOW_QUOTES_BASE_URL,
	headers: { "Content-Type": "application/json" },
});

export const getQuotes = async (
	symbol: string,
	token: string | null
): Promise<QuoteData[]> => {
	try {
		let res;
		if (token) {
			res = await moneyFlowQuotesAxiosInstance.get("", {
				params: { symbol: symbol },
				// headers: { Authorization: `Bearer ${token}` },
			});
		} else {
			res = await moneyFlowQuotesAxiosInstance.get("", {
				params: { symbol: symbol },
			});
		}
		const data: QuoteData[] = res.data;
		return data;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return [] as QuoteData[];
	}
};

export const getInPrices = async (
	symbol: string,
	inPrice: number,
	contractMonth: string,
	token: string | null
): Promise<InPriceData> => {
	try {
		let res;
		if (token) {
			res = await moneyFlowQuotesAxiosInstance.get("/in-price", {
				params: {
					symbol: symbol,
					in_price: inPrice,
					contract_month: contractMonth,
				},
				// headers: { Authorization: `Bearer ${token}` },
			});
		} else {
			res = await moneyFlowQuotesAxiosInstance.get("/in-price", {
				params: {
					symbol: symbol,
					in_price: inPrice,
					contract_month: contractMonth,
				},
			});
		}
		const data: InPriceData = res.data;
		return data;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return {} as InPriceData;
	}
};

export const getInPriceInverse = async (
	symbol: string,
	contractMonth: string,
	token: string | null
): Promise<InPriceInverseData> => {
	try {
		let res;
		if (token) {
			res = await moneyFlowQuotesAxiosInstance.get("/in-price-inverse", {
				params: {
					symbol: symbol,
					contract_month: contractMonth,
					reference_price: 50,
				},
				// headers: { Authorization: `Bearer ${token}` },
			});
		} else {
			res = await moneyFlowQuotesAxiosInstance.get("/in-price-inverse", {
				params: {
					symbol: symbol,
					contract_month: contractMonth,
					reference_price: 50,
				},
			});
		}
		const data: InPriceInverseData = res.data;
		return data;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return {} as InPriceInverseData;
	}
};

export const getContractMonths = async (
	token: string | null
): Promise<string[]> => {
	try {
		let res;
		if (token) {
			res = await moneyFlowQuotesAxiosInstance.get("/contract-month", {
				// headers: { Authorization: `Bearer ${token}` },
			});
		} else {
			res = await moneyFlowQuotesAxiosInstance.get("/contract-month");
		}
		const data: string[] = res.data;
		return data;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return [];
	}
};
