import {
	Autocomplete,
	Button,
	Grid,
	TextField,
	Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import {
	getStableWin,
	getStableWinContactMonth,
} from "../../utils/axios/stableWin";
import { StableWin } from "../../schemas/StableWin";
import { Line } from "react-chartjs-2";
import {
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip,
	TooltipItem,
} from "chart.js";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { CustomJwtPayload } from "../../components/Header/header";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const StableWinPage = () => {
	const [submit, setSubmit] = useState(false);
	const [stableWin, setStableWin] = useState<StableWin[]>([]);
	const [contractMonth, setContractMonth] = useState("");

	const jwt = localStorage.getItem("token");

	const onSubmitHandler = async () => {
		if (contractMonth === "") {
			toast.warn("請輸入期結日");
			return;
		}
		setSubmit(true);

		const result = await getStableWin(jwt, contractMonth);
		setStableWin(result);
	};

	const { data: contractMonths } = useQuery({
		queryKey: ["contractMonth"],
		queryFn: ({ queryKey }) => getStableWinContactMonth(null, "HHI.HK"),
		refetchOnWindowFocus: false,
	});

	const data = {
		labels: stableWin.map((e) => e.in_price.final),
		datasets: [
			{
				label: "RIC Spread 100 報價",
				data: stableWin.map((e) => e.price),
				borderColor: "rgb(255, 99, 132)",
				backgroundColor: "rgba(255, 99, 132, 0.5)",
			},
		],
	};

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: "top" as const,
			},
			title: {
				display: true,
				text: "Stable Win",
			},
			tooltip: {
				enabled: true,
				mode: "index" as "index",
				intersect: false,
				position: "nearest" as "nearest",
				callbacks: {
					label: (tooltipItem: TooltipItem<"line">) => {
						const result = stableWin[tooltipItem.dataIndex];
						const label = [
							`報價: ${tooltipItem.parsed.y}`,
							`入價: ${result.in_price.final}`,
							`Call 入價: ${result.in_price.call}`,
							`Put 入價: ${result.in_price.put}`,
							`更新時間: ${dayjs(result.timestamp)
								.add(8, "hour")
								.format("YYYY-MM-DD HH:mm:ss")}`,
							`notes: ${result.notes}`,
						];
						return label;
					},
				},
			},
		},
	};

	const navigate = useNavigate();
	useEffect(() => {
		const jwt = localStorage.getItem("token");
		if (!jwt) {
			navigate("/home");
			return;
		}
		const payload = jwtDecode<CustomJwtPayload>(jwt);

		if (![352264299, 1125124970].includes(payload.telegram_id))
			navigate("/home");
	});

	return (
		<>
			<Grid container justifyContent={"center"} sx={{ mt: 3 }}>
				<Grid item>
					<Typography variant="h3">Stable Win</Typography>
				</Grid>
			</Grid>

			<Grid container justifyContent={"center"} sx={{ mt: 3 }}>
				<Grid item>
					<Autocomplete
						disabled={submit}
						disableClearable={true}
						value={contractMonth}
						onChange={(_, newValue) => setContractMonth(newValue)}
						options={contractMonths ?? []}
						renderInput={(params) => (
							<TextField {...params} label="期結日" />
						)}
						sx={{ width: 150, mr: 3 }}
					/>
				</Grid>
				<Grid item>
					{submit ? (
						<Button
							style={{ height: 55 }}
							onClick={() => window.location.reload()}
							variant="contained"
						>
							重置
						</Button>
					) : (
						<Button
							style={{ height: 55 }}
							type="submit"
							variant="contained"
							onClick={onSubmitHandler}
						>
							提交
						</Button>
					)}
				</Grid>
			</Grid>

			{!isEmpty(stableWin) && <Line data={data} options={options} />}
		</>
	);
};

export default StableWinPage;
