import {
	Typography,
	Button,
	AppBar,
	Toolbar,
	IconButton,
	Box,
	Drawer,
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Menu,
	MenuItem,
	Collapse,
	Icon,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes/routes";
import jwtDecode, { JwtPayload } from "jwt-decode";

export interface CustomJwtPayload extends JwtPayload {
	telegram_id: number;
	phone: string;
}

const HeaderBar = () => {
	const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
	const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
	const menuOpen = Boolean(menuAnchor);
	const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

	const navigate = useNavigate();

	const jwt = localStorage.getItem("token");
	let payload: CustomJwtPayload;
	if (!jwt) payload = { telegram_id: 0, phone: "" };
	else payload = jwtDecode<CustomJwtPayload>(jwt);

	const drawer = (
		<Box sx={{ textAlign: "center" }}>
			<Typography variant="h6" sx={{ my: 2 }}>
				QuantRaiser Web
			</Typography>
			<Divider />
			<List>
				{routes.map(
					(route) =>
						((route.admin &&
							[1125124970, 352264299].includes(
								payload.telegram_id
							)) ||
							(!route.admin && route.showHeader)) && (
							<Box key={route.title}>
								<ListItem>
									{route.redirect ? (
										<a
											href={route.redirect}
											target="_blank"
										>
											<Typography variant="h6">
												{route.title}
											</Typography>
										</a>
									) : (
										<ListItemButton
											onClick={
												route.children
													? () =>
															setMobileMenuOpen(
																!mobileMenuOpen
															)
													: () => {
															setDrawerOpen(
																!drawerOpen
															);
															navigate(
																route.path
															);
													  }
											}
											sx={{ textAlign: "center" }}
										>
											<ListItemText
												primary={route.title}
											/>
											{route.children ? (
												mobileMenuOpen ? (
													<ExpandLess />
												) : (
													<ExpandMore />
												)
											) : (
												<Icon />
											)}
										</ListItemButton>
									)}
								</ListItem>
								{route.children && (
									<Collapse
										in={mobileMenuOpen}
										timeout="auto"
										unmountOnExit
									>
										<List disablePadding>
											{route.children.map(
												(nestedRoute) => (
													<ListItemButton
														sx={{
															backgroundColor:
																"#ef910a",
															textAlign: "center",
														}}
														onClick={() => {
															setDrawerOpen(
																!drawerOpen
															);
															navigate(
																`${route.path}${nestedRoute.path}`
															);
														}}
														key={nestedRoute.path}
													>
														<ListItemText
															primary={
																nestedRoute.title
															}
														/>
														<Icon />
													</ListItemButton>
												)
											)}
										</List>
									</Collapse>
								)}
							</Box>
						)
				)}
			</List>
		</Box>
	);

	return (
		<>
			<AppBar position="static" sx={{ backgroundColor: "#f7ac40" }}>
				<Toolbar>
					<IconButton
						color="inherit"
						edge="start"
						onClick={() => setDrawerOpen(!drawerOpen)}
						sx={{ display: { sm: "none" } }}
					>
						<MenuIcon />
					</IconButton>
					<Typography
						sx={{ ml: 2, whiteSpace: "nowrap" }}
						variant="h5"
					>
						QuantRaiser Web
					</Typography>
					<Box
						sx={{
							display: { xs: "none", sm: "flex" },
						}}
						justifyContent="center"
						textAlign="center"
						width="100%"
					>
						{routes.map(
							(route) =>
								((route.admin &&
									[1125124970, 352264299].includes(
										payload.telegram_id
									)) ||
									(!route.admin && route.showHeader)) && (
									<Box key={route.title} sx={{ ml: 2 }}>
										{route.redirect ? (
											<a
												href={route.redirect}
												target="_blank"
											>
												<Typography variant="h6">
													{route.title}
												</Typography>
											</a>
										) : (
											<Button
												color="inherit"
												onClick={
													route.children
														? (e) =>
																setMenuAnchor(
																	e.currentTarget
																)
														: () =>
																navigate(
																	route.path
																)
												}
											>
												<Typography variant="h6">
													{route.title}
												</Typography>
												{route.children ? (
													menuOpen ? (
														<ExpandLess />
													) : (
														<ExpandMore />
													)
												) : (
													<></>
												)}
											</Button>
										)}
										{route.children && (
											<Menu
												anchorEl={menuAnchor}
												open={menuOpen}
												onClose={() =>
													setMenuAnchor(null)
												}
											>
												{route.children.map(
													(nestedRoute) => (
														<MenuItem
															onClick={() => {
																setMenuAnchor(
																	null
																);
																navigate(
																	`${route.path}${nestedRoute.path}`
																);
															}}
															key={
																nestedRoute.path
															}
														>
															{nestedRoute.title}
														</MenuItem>
													)
												)}
											</Menu>
										)}
									</Box>
								)
						)}
					</Box>
				</Toolbar>
			</AppBar>
			<Box>
				<Drawer
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					open={drawerOpen}
					onClose={() => setDrawerOpen(!drawerOpen)}
					sx={{
						display: { xs: "block", sm: "none" },
						"& .MuiDrawer-paper": {
							backgroundColor: "#f7ac40",
							boxSizing: "border-box",
							color: "white",
							width: 240,
						},
					}}
					variant="temporary"
				>
					{drawer}
				</Drawer>
			</Box>
		</>
	);
};

export default HeaderBar;
