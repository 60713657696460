import { Box, Tabs, Tab, Typography, Grid } from "@mui/material";
import { useState } from "react";
import IndicatorForm from "../components/Indicator/IndicatorForm";
import { IndicatorData, TIndicatorForm } from "../schemas/Indicator";
import { getResult, getSmaCrossResult } from "../utils/axios/indicator";
import { toast } from "react-toastify";
import {
	DataGrid,
	GridColDef,
	GridValueFormatterParams,
} from "@mui/x-data-grid";
import SmaCrossForm from "../components/Indicator/SmaCrossForm";
import { TSmaCrossForm } from "../schemas/Indicator/smaCross";
import CustomTabPanel from "../components/Common/CustomTabPanel";

const columns: GridColDef[] = [
	{
		field: "buy_date",
		align: "center",
		headerAlign: "center",
		headerName: "開始日期",
		flex: 1,
	},
	{
		field: "sell_date",
		align: "center",
		headerAlign: "center",
		headerName: "完結日期",
		flex: 1,
	},
	{
		field: "buy_price",
		align: "center",
		headerAlign: "center",
		headerName: "買價",
		flex: 1,
		valueFormatter: (params: GridValueFormatterParams<number>) => {
			if (params.value == null) {
				return "";
			}
			return params.value.toFixed(2);
		},
	},
	{
		field: "sell_price",
		align: "center",
		headerAlign: "center",
		headerName: "賣價",
		flex: 1,
		valueFormatter: (params: GridValueFormatterParams<number>) => {
			if (params.value == null) {
				return "";
			}
			return params.value.toFixed(2);
		},
	},
	{
		field: "max_high",
		align: "center",
		headerAlign: "center",
		headerName: "最高",
		flex: 1,
		valueFormatter: (params: GridValueFormatterParams<number>) => {
			if (params.value == null) {
				return "";
			}
			return params.value.toFixed(2);
		},
	},
	{
		field: "min_low",
		align: "center",
		headerAlign: "center",
		headerName: "最低",
		flex: 1,
		valueFormatter: (params: GridValueFormatterParams<number>) => {
			if (params.value == null) {
				return "";
			}
			return params.value.toFixed(2);
		},
	},
	{
		field: "max_high_volatility",
		align: "center",
		headerAlign: "center",
		headerName: "升波幅",
		flex: 1,
		valueGetter: (params) => {
			if (!params.value) {
				return params.value;
			}
			return params.value * 100;
		},
		valueFormatter: (params: GridValueFormatterParams<number>) => {
			if (params.value == null) {
				return "";
			}
			return `${params.value.toFixed(2)} %`;
		},
	},
	{
		field: "min_low_volatility",
		align: "center",
		headerAlign: "center",
		headerName: "跌波幅",
		flex: 1,
		valueGetter: (params) => {
			if (!params.value) {
				return params.value;
			}
			return params.value * 100;
		},
		valueFormatter: (params: GridValueFormatterParams<number>) => {
			if (params.value == null) {
				return "";
			}
			return `${params.value.toFixed(2)} %`;
		},
	},
	{
		field: "abs_max_volatility",
		align: "center",
		headerAlign: "center",
		headerName: "最大波幅",
		flex: 1,
		valueGetter: (params) => {
			if (!params.value) {
				return params.value;
			}
			return params.value * 100;
		},
		valueFormatter: (params: GridValueFormatterParams<number>) => {
			if (params.value == null) {
				return "";
			}
			return `${params.value.toFixed(2)} %`;
		},
	},
	{
		field: "pos_end_volatility",
		align: "center",
		headerAlign: "center",
		headerName: "升結波幅",
		flex: 1,
		valueGetter: (params) => {
			if (!params.value) {
				return params.value;
			}
			return params.value * 100;
		},
		valueFormatter: (params: GridValueFormatterParams<number>) => {
			if (params.value == null) {
				return "";
			}
			return `${params.value.toFixed(2)} %`;
		},
	},
	{
		field: "neg_end_volatility",
		align: "center",
		headerAlign: "center",
		headerName: "跌結波幅",
		flex: 1,
		valueGetter: (params) => {
			if (!params.value) {
				return params.value;
			}
			return params.value * 100;
		},
		valueFormatter: (params: GridValueFormatterParams<number>) => {
			if (params.value == null) {
				return "";
			}
			return `${params.value.toFixed(2)} %`;
		},
	},
	{
		field: "abs_end_volatility",
		align: "center",
		headerAlign: "center",
		headerName: "最大結波幅",
		flex: 1,
		valueGetter: (params) => {
			if (!params.value) {
				return params.value;
			}
			return params.value * 100;
		},
		valueFormatter: (params: GridValueFormatterParams<number>) => {
			if (params.value == null) {
				return "";
			}
			return `${params.value.toFixed(2)} %`;
		},
	},
];

const IndicatorPage = () => {
	const [indicator, setIndicator] = useState(0);
	const [indicatorData, setIndicatorData] = useState<IndicatorData[]>([]);
	const [loading, setLoading] = useState(false);

	const onSubmitHandler = async (data: TIndicatorForm) => {
		setLoading(true);
		try {
			const result = await getResult(data);
			setIndicatorData(result);
		} catch (error) {
			toast.error("發生錯誤");
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	const onSmaCrossSubmitHandler = async (data: TSmaCrossForm) => {
		setLoading(true);
		try {
			const result = await getSmaCrossResult(data);
			setIndicatorData(result);
			toast.success("成功");
		} catch (error) {
			toast.error("發生錯誤");
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Grid container justifyContent={"center"} sx={{ mt: 3 }}>
				<Grid item>
					<Typography variant="h3">技術指標機率表</Typography>
				</Grid>
			</Grid>
			<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
				<Tabs
					value={indicator}
					onChange={(e, newValue) => setIndicator(newValue)}
				>
					<Tab label="SMA Cross" />
					{/* <Tab label="SMA Gap" /> */}
				</Tabs>
			</Box>
			<CustomTabPanel state={indicator} index={0}>
				<SmaCrossForm
					onSubmitHandler={onSmaCrossSubmitHandler}
					loading={loading}
				/>
				<Box
					sx={{
						width: "100%",
						mt: 10,
					}}
				>
					<DataGrid
						autoHeight
						rows={indicatorData}
						columns={columns}
						getRowId={(row) => row.buy_date}
						loading={loading}
					/>
				</Box>
			</CustomTabPanel>
			<CustomTabPanel state={indicator} index={1}>
				<IndicatorForm
					onSubmitHandler={onSubmitHandler}
					loading={loading}
				/>
				<Box
					sx={{
						width: "100%",
						mt: 10,
					}}
				>
					<DataGrid
						autoHeight
						rows={indicatorData}
						columns={columns}
						// disableColumnMenu
						// hideFooter={true}
						getRowId={(row) => row.buy_date}
					/>
				</Box>
			</CustomTabPanel>
		</>
	);
};

export default IndicatorPage;
