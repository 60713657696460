import axios from "axios";
import { toast } from "react-toastify";
import { IMoneyFlowUsData } from "../../schemas/MoneyFlowUs";

const MoneyFlowUsAxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_MONEY_FLOW_US_BASE_URL,
	headers: { "Content-Type": "application/json" },
});

export const listSymbol = async (token: string | null): Promise<string[]> => {
	try {
		let res;
		if (token) {
			res = await MoneyFlowUsAxiosInstance.get("/symbol", {
				// headers: {
				// 	Authorization: `Bearer ${token}`,
				// },
			});
		} else {
			res = await MoneyFlowUsAxiosInstance.get("/symbol");
		}
		return res.data.data;
	} catch (err) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + err);
		return [];
	}
};

export const listContractMonth = async (
	token: string | null,
	symbol: string
): Promise<string[]> => {
	try {
		let res;
		if (token) {
			res = await MoneyFlowUsAxiosInstance.get(
				`/contract-month/${symbol}`,
				{
					// headers: {
					// 	Authorization: `Bearer ${token}`,
					// },
				}
			);
		} else {
			res = await MoneyFlowUsAxiosInstance.get(
				`/contract-month/${symbol}`
			);
		}
		return res.data.data;
	} catch (err) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + err);
		return [];
	}
};

export const listOptionData = async (
	token: string | null,
	symbol: string,
	contractMonth: string
): Promise<IMoneyFlowUsData> => {
	try {
		let res;
		if (token) {
			res = await MoneyFlowUsAxiosInstance.get("", {
				// headers: {
				// 	Authorization: `Bearer ${token}`,
				// },
				params: {
					symbol,
					contract_month: contractMonth,
				},
			});
		} else {
			res = await MoneyFlowUsAxiosInstance.get("", {
				params: {
					symbol,
					contract_month: contractMonth,
				},
			});
		}
		return res.data.data;
	} catch (err) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + err);
		return {} as IMoneyFlowUsData;
	}
};
