import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import {
	OptionTable,
	OptionTableForm,
	OptionTableModal,
} from "../../components/OptionTable";
import { OptionTableType } from "../../schemas/OptionTable";

const OptionTablePage = () => {
	const [price, setPrice] = useState("");
	const [range, setRange] = useState("");
	const [data, setData] = useState<OptionTableType[]>([]);
	const [modalOpen, setModalOpen] = useState(false);

	return (
		<>
			<Grid container justifyContent={"center"} sx={{ mt: 3 }}>
				<Grid item>
					<Typography variant="h3">期權計價表</Typography>
				</Grid>
			</Grid>

			<OptionTableForm
				price={price}
				setPrice={setPrice}
				range={range}
				setRange={setRange}
				setModalOpen={setModalOpen}
				data={data}
				setData={setData}
			/>

			<OptionTableModal
				modalOpen={modalOpen}
				setModalOpen={setModalOpen}
				data={data}
				setData={setData}
			/>

			{data.length === 0 ? (
				<></>
			) : (
				<OptionTable data={data} price={price} range={range} />
			)}
		</>
	);
};

export default OptionTablePage;
