import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { DataTable, ExcelForm } from "../../components/Excel";
import { ExcelWebType, IExcelInput } from "../../schemas/ExcelWeb";
import { getExcelWebData } from "../../utils/axios/excelWeb";

const ExcelPage = () => {
	const [data, setData] = useState<ExcelWebType[]>([]);

	const onFormSubmit = async (data: IExcelInput) => {
		const jwt = localStorage.getItem("token");
		setData(await getExcelWebData(data, jwt));
	};

	return (
		<>
			<Grid container justifyContent={"center"} sx={{ mt: 3 }}>
				<Grid item>
					<Typography variant="h3">Data對數表</Typography>
				</Grid>
			</Grid>

			<Grid container sx={{ mt: 3 }}>
				<Grid item xs={12}>
					<ExcelForm onFormSubmit={onFormSubmit} />
				</Grid>
			</Grid>

			<Grid container sx={{ mt: 3 }}>
				<Grid item xs={12}>
					<DataTable data={data} />
				</Grid>
			</Grid>
		</>
	);
};

export default ExcelPage;
