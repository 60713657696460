import axios from "axios";
import { toast } from "react-toastify";
import { StableWin } from "../../schemas/StableWin";

const StableWinAxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_STABLE_WIN_BASE_URL,
	headers: { "Content-Type": "application/json" },
});

export const getStableWinContactMonth = async (
	token: string | null,
	symbol: string
): Promise<string[]> => {
	try {
		let res;
		if (token) {
			res = await StableWinAxiosInstance.get(
				`/contract-month/${symbol}`,
				{
					// headers: {
					// 	Authorization: `Bearer ${token}`,
					// },
				}
			);
		} else {
			res = await StableWinAxiosInstance.get(`/contract-month/${symbol}`);
		}
		return res.data.data;
	} catch (err) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + err);
		return [];
	}
};

export const getStableWin = async (
	token: string | null,
	contractMonth: string
): Promise<StableWin[]> => {
	try {
		let res;
		if (token) {
			res = await StableWinAxiosInstance.get(`/${contractMonth}`, {
				// headers: {
				// 	Authorization: `Bearer ${token}`,
				// },
			});
		} else {
			res = await StableWinAxiosInstance.get(`/${contractMonth}`);
		}
		const data: StableWin[] = res.data.data;
		return data;
	} catch (err) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + err);
		return [];
	}
};
