import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
} from "@mui/material";
import { MouseEvent, useState } from "react";
import { ExcelWebType } from "../../schemas/ExcelWeb";

interface HeadCell {
	id: string;
	label: string;
}

const headCells: HeadCell[] = [
	{ id: "real_start_date", label: "開始日期" },
	{ id: "real_end_date", label: "完結日期" },
	{ id: "buy_price", label: "買價" },
	{ id: "sell_price", label: "賣價" },
	{ id: "max_high", label: "最高" },
	{ id: "min_low", label: "最低" },
	{ id: "max_high_volatility", label: "升波幅" },
	{ id: "min_low_volatility", label: "跌波幅" },
	{ id: "abs_max_volatility", label: "最大波幅" },
	{ id: "pos_end_volatility", label: "升結波幅" },
	{ id: "neg_end_volatility", label: "跌結波幅" },
	{ id: "abs_end_volatility", label: "最大結波幅" },
	{ id: "max_high_date", label: "升波幅日期" },
	{ id: "min_low_date", label: "跌波幅日期" },
	{ id: "abs_max_date", label: "最大波幅日期" },
];

type IDataTable = {
	data: ExcelWebType[];
};

type Order = "asc" | "desc";

const DataTable = ({ data }: IDataTable) => {
	const [order, setOrder] = useState<Order>("asc");
	const [orderBy, setOrderBy] = useState("real_start_date");

	const onClickSortHandler = (id: string) => (_: MouseEvent<unknown>) => {
		const isAsc = orderBy === id && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(id);
	};

	const compare = (a: ExcelWebType, b: ExcelWebType) => {
		if (
			a[orderBy as keyof ExcelWebType] < b[orderBy as keyof ExcelWebType]
		) {
			if (
				orderBy === "min_low_volatility" ||
				orderBy === "neg_end_volatility"
			)
				return order === "asc" ? 1 : -1;
			else return order === "asc" ? -1 : 1;
		}

		if (
			a[orderBy as keyof ExcelWebType] > b[orderBy as keyof ExcelWebType]
		) {
			if (
				orderBy === "min_low_volatility" ||
				orderBy === "neg_end_volatility"
			)
				return order === "asc" ? -1 : 1;
			else return order === "asc" ? 1 : -1;
		}

		return 0;
	};

	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						{headCells.map((headCell) => (
							<TableCell
								key={headCell.id}
								sortDirection={
									orderBy === headCell.id ? order : false
								}
								sx={{ whiteSpace: "nowrap" }}
							>
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={
										orderBy === headCell.id ? order : "asc"
									}
									onClick={onClickSortHandler(headCell.id)}
								>
									{headCell.label}
								</TableSortLabel>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{data.length === 0 ? (
						<></>
					) : (
						data.sort(compare).map((yearData) => {
							return (
								<TableRow key={yearData.year}>
									<TableCell>
										{yearData.real_start_date}
									</TableCell>
									<TableCell>
										{yearData.real_end_date}
									</TableCell>
									<TableCell>
										{yearData.buy_price.toFixed(2)}
									</TableCell>
									<TableCell>
										{yearData.sell_price.toFixed(2)}
									</TableCell>
									<TableCell>
										{yearData.max_high.toFixed(2)}
									</TableCell>
									<TableCell>
										{yearData.min_low.toFixed(2)}
									</TableCell>
									<TableCell>
										{(
											yearData.max_high_volatility * 100
										).toFixed(2)}
										%
									</TableCell>
									<TableCell>
										{(
											yearData.min_low_volatility * 100
										).toFixed(2)}
										%
									</TableCell>
									<TableCell>
										{(
											yearData.abs_max_volatility * 100
										).toFixed(2)}
										%
									</TableCell>
									<TableCell>
										{(
											yearData.pos_end_volatility * 100
										).toFixed(2)}
										%
									</TableCell>
									<TableCell>
										{(
											yearData.neg_end_volatility * 100
										).toFixed(2)}
										%
									</TableCell>
									<TableCell>
										{(
											yearData.abs_end_volatility * 100
										).toFixed(2)}
										%
									</TableCell>
									<TableCell>
										{yearData.max_high_date}
									</TableCell>
									<TableCell>
										{yearData.min_low_date}
									</TableCell>
									<TableCell>
										{yearData.abs_max_date}
									</TableCell>
								</TableRow>
							);
						})
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default DataTable;
