import { Box } from "@mui/material";

interface ICustomTabPanel {
	index: number;
	state: number;
	children?: React.ReactNode;
}

const CustomTabPanel = ({ index, state, children }: ICustomTabPanel) => {
	return <>{state === index && <Box sx={{ p: 3 }}>{children}</Box>}</>;
};
export default CustomTabPanel;
