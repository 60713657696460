import axios from "axios";
import { toast } from "react-toastify";
import { Response } from "../../schemas/Response";

const dividendAxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_DIVIDEND_BASE_URL,
	headers: { "Content-Type": "application/json" },
});

const dividendIdAxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_DIVIDEND_CONTENT_BASE_URL,
	headers: { "Content-Type": "application/json" },
});

export interface IDividend {
	[key: string]: IDividendContent;
}

export interface IDividendContent {
	call: IDividendCallData[];
	put: IDividendPutData[];
}

export interface IDividendData {
	"C/P": string;
	"CONTRACT MONTH": string;
	DATE: string;
	DIVIDEND: number;
	"DRAW PRICE": number;
	"EXPIRY DATE": number;
	"HKAT CODE": string;
	IV: number;
	MULTIPLIER: number;
	"NOW PRICE": number;
	"OPEN INTEREST": number;
	"REMAINING DAYS": number;
	"SETTLEMENT PRICE": number;
	"STRIKE PRICE": number;
	SYMBOL: string;
	VOLUME: number;
	NAME: string;
}

export interface IDividendCallData extends IDividendData {
	"CALL AMOUNT": number;
}

export interface IDividendPutData extends IDividendData {
	"PUT AMOUNT": number;
}

export const listDividend = async (
	token: string | null
): Promise<IDividend> => {
	try {
		let res;
		if (token) {
			res = await dividendAxiosInstance.get("/", {
				// headers: {
				// 	Authorization: `Bearer ${token}`,
				// },
			});
		} else {
			res = await dividendAxiosInstance.get("/");
		}
		const data = res.data;
		if (data.status !== 200) {
			toast.error("發生錯誤");
			console.error(data);
			return {};
		}
		return data.data;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
		return {};
	}
};

export const getDividendId = async (
	token: string | null,
	id: string | undefined
) => {
	try {
		let res;
		if (token) {
			res = await dividendIdAxiosInstance.get(`/${id}`, {
				// headers: {
				// 	Authorization: `Bearer ${token}`,
				// },
			});
		} else {
			res = await dividendIdAxiosInstance.get(`/${id}`);
		}
		const data: Response = res.data;
		if (data.status !== 200) {
			toast.error("發生錯誤");
			console.error(data);
			return {};
		}
		return data.data;
	} catch (error) {
		toast.error("發生錯誤");
		console.error("Fetch api error: " + error);
	}
};
